import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import axios from 'axios';
import '../css/QuestionAndAnswer.scss';

function QuestionAndAnswer() {

    const [faq, setFaq] = useState(null);

    const [searchParams] = useSearchParams();
    const faqId = searchParams.get("faqid");

    useEffect(() => {
        document.title = "问答";
        document.querySelector('#root').className = 'question-and-answer';
    },[])

    useEffect(() => {
        axios.get(`/wp-json/hello-world/v1/get-faq-by-id/${faqId}`).then(res => {
            setFaq(res.data);
        })
    }, [faqId])

    console.log(faq);

    return (
        <>
            <header className='header'>
                <div className='container'>
                    <Nav />
                </div>
            </header>

            <section className='question-and-answer__section'>
                <div className='container'>
                    <h1 className='question-and-answer__title'>信仰 问答</h1>
                    <div className='question-and-answer__content'>
                        <div className='question-and-answer__item'>
                            {faq ? (    
                                <>
                                    <h2 className='question-and-answer__item-title'>{faq?.title}</h2>
                                    <div className='question-and-answer__item-content' dangerouslySetInnerHTML={{ __html: faq?.content }} />
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default QuestionAndAnswer;