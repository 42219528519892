// generate service hello world
import React, { useEffect } from 'react';
import Nav from './Nav';
import Footer from './Footer';
import '../css/service.scss';

function Service() {

    useEffect(() => {
        document.title = "7G.tv 服务条款";
        document.querySelector("#root").className = "service";
    }, []);

    return (
        <>
            <header className="header">
                <div className="container">
                    <Nav />
                </div>
            </header>

            <div className="service-content">
                <div className="my-container">
                    <nav className="service-sidebar">
                        <ul className="service-toc">
                            <li><a href="#welcome" className="active">欢迎使用7G.tv</a></li>
                            <li><a href="#service">使用服务</a></li>
                            <li><a href="#account">7G.tv账户</a></li>
                            <li><a href="#privacy">隐私与版权保护</a></li>
                            <li><a href="#publish-assignment">您在我们服务中发布的任务</a></li>
                            <li><a href="#stop-assignment">修改和终止任务</a></li>
                            <li><a href="#about">关于本条款</a></li>
                        </ul>
                    </nav>
                    <div className="service-main">
                        <h1 id="top" className="fangzheng">7G.tv服务条款</h1>

                        <section>
                            <h2 id="welcome">欢迎使用7G.tv</h2>
                            <h3>感谢您使用我们的产品和服务（下称“服务”）。服务由7G.tv提供。</h3>
                            <p>您使用我们的服务即表示您已同意本条款。请仔细阅读。</p>
                            <p>我们的服务范围非常广泛，因此有时还会适用一些附加条款或产品要求（包括年龄要求）。附加条款将会与相关服务一同提供，并且在您使用这些服务后，成为您与我们所达成的协议的一部分。</p>
                        </section>

                        <section>
                            <h2 id="service">使用服务</h2>
                            <p>您必须遵守服务中提供的所有政策。</p>
                            <p>请勿滥用我们的服务。举例而言，请勿干扰我们的服务或尝试使用除我们提供的界面和指示以外的方法访问这些服务。您仅能在法律允许的范围内使用我们的服务。如果您不遵守我们的条款或政策，或者我们在调查可疑的不当行为，我们可以暂停或停止向您提供服务。</p>
                            <p>使用我们的服务并不让您拥有我们的服务或您所访问的内容的任何知识产权。除非您获得相关内容所有者的许可或通过其他方法获得法律的许可，否则您不得使用服务中的任何内容。本条款并未授权您使用我们服务中所用的任何商标或标志的权利。请勿删除、隐藏或更改我们服务上显示的或随服务一同显示的任何法律声明。</p>
                            <p>我们的服务会显示一些不属于7G.tv的内容，这些内容由发布的实体承担全部责任。我们可能会审查相关内容，以确定其是否违法或违反了我们的政策；如果我们有理由相信该内容违反了我们的政策或违法，我们可以将其删除或拒绝显示。不过，这并不意味我们必然会审查内容，因此请勿想当然地认为我们在进行审查。</p>
                            <p>在您使用服务的过程中，我们可能会向您发送服务公告、管理消息和其他信息。您可以选择不接收上述某些信息。我们的部分服务可在移动设备上使用。在使用此类服务时，请勿因此而分散注意力和违反交通或安全法。</p>
                        </section>

                        <section>
                            <h2 id="account">7G.tv账户</h2>
                            <p>为了使用我们的某些服务，您可能需要一个7G.tv账户。您可以创建自己的7G.tv账户或者由管理员（例如您所在的单位或教育机构）为您分配7G.tv账户。如果您使用的是由管理员分配的7G.tv账户，可能需要遵守另外的条款或附加条款，并且您的管理员可能有权访问或停用您的账户。</p>
                            <p>为保护您的7G.tv账户，请务必保管好您的密码并对外保密。您应对自己7G.tv账户上发生的活动或通过该账户进行的活动负责。尽量不要在第三方应用中使用与7G.tv账户相同的密码。如果您发现有人在未经授权的情况下使用了您的密码或7G.tv账户，请重设密码，这样他人就无法登录您的账户。如果您无法重设7G.tv账户密码，并且无法登录到您的账户中，请联络我们 <a href="mailto:webmaster@7g.tv">webmaster@7g.tv</a>。</p>
                        </section>

                        <section>
                            <h2 id="privacy">隐私与版权保护</h2>
                            <p>7G.tv的<a href="#">隐私权政策</a>介绍了您在使用我们的服务时，我们会如何处理您的个人数据和保护您的隐私。使用我们的服务即表示您同意7G.tv可以按照我们的隐私权政策使用您的个人数据。</p>
                            <p>我们会向版权持有人提供信息，以帮助他们在线管理自己的知识产权。</p>
                            <p>如果您认为有人侵犯了您的版权或发现7G.tv上存在可能违法的内容，请告诉我们。我们将会仔细审核相关资料，然后阻止、删除或限制对此类内容的访问。7G.tv服务上的滥用内容也可能会违反7G.tv产品政策，因此向我们发送法律要求之前，请先举报相关信息、图片或视频，供我们的某个内容小组审核。</p>
                        </section>

                        <section>
                            <h2 id="publish-assignment">您在我们服务中发布的任务</h2>
                            <p>我们的某些服务允许您上传、提交、存储、发送或接收内容。您保留对该内容持有的任何知识产权的所有权。简言之，属于您的内容依然归您所有。</p>
                            <p>当您将内容上传、提交、存储或发送到我们的服务，以及通过我们的服务上传、提交、存储、发送或接收内容时，您授予7G.tv（以及我们的合作伙伴）一项全球性的许可，允许7G.tv使用、托管、存储、复制、修改、创建衍生作品（例如，我们为了使您的内容更好地与我们的服务配合使用而进行翻译、改编或其他更改，由此产生的作品）、传播、出版、公开演示、公开展示和分类此类内容。您在此许可中授予的权限，仅能用于运营、宣传和改进我们的服务，以及开发新的服务。该项许可在您停止使用我们的服务后依然有效。某些服务可能会向您提供一些方法，用于访问和删除您提供给该服务的内容。此外，我们某些服务中的条款或设置还会收窄我们对提交至相关服务的内容的使用范围。</p>
                        </section>

                        <section>
                            <h2 id="stop-assignment">修改和终止任务</h2>
                            <p>我们始终在不断更改和改进我们的服务。我们可能会增加或删除功能，也可能暂停或彻底停止某项服务。</p>
                            <p>您可以随时停止使用我们的服务，尽管我们对此表示非常遗憾。7G.tv也可能随时停止向您提供服务，或随时对我们的服务增加或设置新的限制。</p>
                            <p>我们认为您拥有自己数据的所有权并保留对此类数据的访问权限，这一点非常重要。如果我们停止某项服务，在合理可能的情况下，我们会向用户发出合理的提前通知，并让用户有机会将信息从服务中导出。</p>
                        </section>

                        <section>
                            <h2 id="about">关于本条款</h2>
                            <p>我们可以修改上述条款或任何适用于某项服务的附加条款，例如，为反映法律的变更或我们服务的变化而进行的修改。您应当定期查阅本条款。我们会在本网页上公布这些条款的修改通知。</p>
                        </section>

                        <a href="#top" className="back-to-top">回到顶部</a>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
}

export default Service;