// src/App.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import Home from './Home';
import Series from './Series';
import Classified from './Classified';
import Wishes from './Wishes';
import Application from './Application';
import Onplay from './Onplay';
import SeriesOnplay from './SeriesOnplay'
import Dev from './Dev'
import SearchResult from './SearchResult'
import QuestionAndAnswer from './QuestionAndAnswer'
import Privacy from './privacy';
import Service from './service';

function App() {

  const { id } = useParams();

  // const Required = ({children}) => {
  //   if (seriesState.isReady) {
  //     return children;
  //   }
  // }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/classified" element={<Classified />} />
        <Route path="/series" element={<Series />} />
        <Route path="/question-and-answer" element={<QuestionAndAnswer />} />
        <Route path="/wishes" element={<Wishes />} />
        <Route path="/application" element={<Application />} />
        <Route path="/onplay/:id" element={<Onplay />} />
        {/* url like /seriesonplay/123?video=123&t=123 */}
        <Route path="/seriesonplay/:seriesId" element={<SeriesOnplay />} />
        {/* <Route path="/dev" element={<Dev />} /> */}
        <Route path="/search-result" element={<SearchResult />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path="/service" element={<Service />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
