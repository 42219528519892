import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../css/Home.scss";
import Nav from "./Nav";
import Footer from "./Footer";
// import LoveIcon from "../static/love_icon.svg";
// import TimeIcon from "../static/time_icon.svg";
// import SubtitleIcon from "../static/subtitle_icon.svg";
// import RatingIcon from "../static/rating_icon.svg";
// import YearIcon from "../static/year_icon.svg";
// import ImgOnTheAir1 from "../static/on-the-air-image-1.png";
// import ImgOnTheAir2 from "../static/on-the-air-image-2.png";
// import ImgSelected1 from "../static/selected_image_1.png";
// import ImgSelected2 from "../static/selected_image_2.png";
// import ImgRecommandations1 from "../static/recommandations-image-1.png";
// import ImgRecommandations2 from "../static/recommandations-image-2.png";
// import ImgSeries1 from "../static/series_image-1.png";
// import ImgSeries2 from "../static/series_image-2.png";
// import ImgSeries3 from "../static/series_image-3.png";
// import btnPrev from "../static/btnPrevious.png";
// import btnNext from "../static/btnNext.png";
// import ImgEvangelize from "../static/evangelize-image.png";
// import ImgEvangelize1 from "../static/evangelize-image-1.png";
// import ImgEvangelize2 from "../static/evangelize-image-2.png";
// import ImgEvangelize3 from "../static/evangelize-image-3.png";
// import ImgEvangelize4 from "../static/evangelize-image-4.png";
import axios from "axios";
import { fetchSeries, fetchSelectedVideos, fetchRecommendVideos, fetchFrequentlyAskedQuestions, fetchSeriesSectionVideos } from "../store/series";
import { convertMicrosecondsToTimeString } from "../utils/utils";

function Home() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { seriesSlides, selectedVideos, recommendVideos, frequentlyAskedQuestions, seriesSectionVideos, isReady, error } = useSelector((state) => state.series);

    const [currentSlide, setCurrentSlide] = useState(0);
    const [isAutoPlaying, setIsAutoPlaying] = useState(true);

    const [seriesCarrousel, setSeriesCarrousel] = useState([])
    const [currentCarrouselPage, setCurrentCarrouselPage] = useState(1);
    const itemsPerCarrouselPage = 5;
    const totalCarrouselPages = Math.ceil(seriesCarrousel.length / itemsPerCarrouselPage);

    // const [selectedVideos, setSelectedVideos] = useState([]);
    const [currentSelectedVideoPage, setCurrentSelectedVideoPage] = useState(1);
    const videosPerPage = 4;
    const totalSelectedVideoPages = Math.ceil(selectedVideos.length / videosPerPage);

    // const [recommendVideos, setRecommendVideos] = useState([]);
    const [currentRecommendVideoPage, setCurrentRecommendVideoPage] = useState(1);
    const recommendVideosPerPage = 10;
    const totalRecommendVideoPages = Math.ceil(recommendVideos.length / recommendVideosPerPage);

    // const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState(1);
    const questionsPerPage = 7;  

    const [evangellizeLeftVideo, setEvangellizeLeftVideo] = useState(null);
    const [evangellizeRightVideo1, setEvangellizeRightVideo1] = useState(null);
    const [evangellizeRightVideo2, setEvangellizeRightVideo2] = useState(null);
    const [evangellizeRightVideo3, setEvangellizeRightVideo3] = useState(null);
    const [evangellizeRightVideo4, setEvangellizeRightVideo4] = useState(null);

    // 1. 添加新的状态来存储预加载的图片
    const [preloadedImages, setPreloadedImages] = useState({});
    const [imagesLoaded, setImagesLoaded] = useState(false);

    // 2. 添加图片预加载工具函数
    const preloadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => reject(url);
        });
    };

    useEffect(() => {
        document.title = "首页 - 7G";
        document.querySelector('#root').className = 'home'

        // 轮播图
        dispatch(fetchSeries());
        // 精选视频
        dispatch(fetchSelectedVideos());
        // 主题推荐
        dispatch(fetchRecommendVideos());
        // 信仰问答
        dispatch(fetchFrequentlyAskedQuestions());
        // 系列区块视频
        dispatch(fetchSeriesSectionVideos());

    }, [dispatch]);

    useEffect(() => {
        const fetchEvangellizeVideo = async (id ) => {
            const response = await axios.get(`/wp-json/hello-world/v1/video/${id}`);
            return response.data;
        };

        fetchEvangellizeVideo(1975).then(data => {
            setEvangellizeLeftVideo(data);
        }); 

        fetchEvangellizeVideo(1867).then(data => {
            setEvangellizeRightVideo1(data);
        }).catch(error => {
            console.error('Error fetching evangelize video:', error);
        });

        fetchEvangellizeVideo(1732).then(data => {
            setEvangellizeRightVideo2(data);
            }).catch(error => {
            console.error('Error fetching evangelize video:', error);
        });

        fetchEvangellizeVideo(1595).then(data => {
            setEvangellizeRightVideo3(data);
        }).catch(error => {
            console.error('Error fetching evangelize video:', error);
        });

        fetchEvangellizeVideo(1842).then(data => {
            setEvangellizeRightVideo4(data);
        }).catch(error => {
            console.error('Error fetching evangelize video:', error);
        });
    }, []);

    useEffect(() => {
        seriesSlides[currentSlide] && setSeriesCarrousel(seriesSlides[currentSlide].articles);
        setCurrentCarrouselPage(1);
    }, [currentSlide, seriesSlides]);

    // Auto-play slides
    useEffect(() => {
        let interval;
        if (isAutoPlaying) {
            // Auto-turn slides every 5 seconds
            interval = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % seriesSlides.length);
            }, 10000);
        }

        return () => clearInterval(interval);
    }, [seriesSlides, isAutoPlaying]);

    // Slides
    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % seriesSlides.length);
        setIsAutoPlaying(false);
    };

    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + seriesSlides.length) % seriesSlides.length);
        setIsAutoPlaying(false);
    };

    const toggleAutoPlay = () => {
        setIsAutoPlaying((prev) => !prev);
    };

    // 3. 在数据加载完成后预加载图片
    useEffect(() => {
        if (seriesSlides.length > 0 && !imagesLoaded) {
            const imagesToPreload = [];
            
            // 收集所有需要预加载的图片URL
            seriesSlides.forEach(slide => {
                // 添加封面图
                if (slide.series.cover_url) {
                    imagesToPreload.push(slide.series.cover_url);
                }
                
                // 添加视频海报
                slide.articles.forEach(article => {
                    if (article.poster_url) {
                        imagesToPreload.push(article.poster_url);
                    }
                });
            });

            // 预加载所有图片
            Promise.all(imagesToPreload.map(url => preloadImage(url)))
                .then(urls => {
                    const imageCache = {};
                    urls.forEach(url => {
                        imageCache[url] = url;
                    });
                    setPreloadedImages(imageCache);
                    setImagesLoaded(true);
                })
                .catch(error => {
                    console.error('Error preloading images:', error);
                    setImagesLoaded(true); // 即使有错误也标记为已加载
                });
        }
    }, [seriesSlides]);

    // 4. 修改图片渲染逻辑，使用预加载的图片
    const getImageUrl = (url) => {
        return preloadedImages[url] || url;
    };

    if (!isReady || !imagesLoaded) {
        // 5. 在加载状态下显示loading
        return <div style={{ color: 'white', fontSize: '1.5rem', textAlign: 'center' }}>
            加载中...
        </div>;
    }

    const inlineStyle = {
        backgroundImage: seriesSlides[currentSlide] && 
            `url(${getImageUrl(seriesSlides[currentSlide].series.cover_url)})`
    };

    // series carrousel
    const getCurrentPageData = () => {

        // when pre/next icon reaches the start and the end of the list , it only show the top 5 items or the last 5 items
        if (currentCarrouselPage === 1) {
            return seriesCarrousel.slice(0, itemsPerCarrouselPage);
        } else if (currentCarrouselPage === totalCarrouselPages) {
            return seriesCarrousel.slice(-itemsPerCarrouselPage);
        } else {
            return seriesCarrousel.slice((currentCarrouselPage - 1) * itemsPerCarrouselPage, currentCarrouselPage * itemsPerCarrouselPage);
        }
    };

    const prevCarrouselPage = () => {
        setCurrentCarrouselPage((prev) => Math.max(prev - 1, 1));
    };

    const nextCarrouselPage = () => {
        setCurrentCarrouselPage((prev) => Math.min(prev + 1, totalCarrouselPages));
    };

    // selected videos
    const getCurrentSelectedVideoPageData = () => {
        const startIndex = (currentSelectedVideoPage - 1) * videosPerPage;
        return selectedVideos.slice(startIndex, startIndex + videosPerPage);
    }

    const handleSelectedVideoPagePrev = () => {
        setCurrentSelectedVideoPage((prev) => Math.max(prev - 1, 1));
    }

    const handleSelectedVideoPageNext = () => {
        setCurrentSelectedVideoPage((prev) => Math.min(prev + 1, totalSelectedVideoPages));
    }

    // recommend videos
    const getCurrentRecommendVideoPageData = () => {
        const startIndex = (currentRecommendVideoPage - 1) * recommendVideosPerPage;
        return recommendVideos.slice(startIndex, startIndex + recommendVideosPerPage);
    }

    const handleRecommendVideoPagePrev = () => {
        setCurrentRecommendVideoPage((prev) => Math.max(prev - 1, 1));
    }

    const handleRecommendVideoPageNext = () => {
        setCurrentRecommendVideoPage((prev) => Math.min(prev + 1, totalRecommendVideoPages));
    }

    // 信仰问答
    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = frequentlyAskedQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
    const totalPages = Math.ceil(frequentlyAskedQuestions.length / questionsPerPage);

    // rewrite pageNumbers as function, create an array of page numbers
    const pageNumbers = (number) => {
        const pageNumbersArr = [];
        for (let i = 1; i <= number; i++) {
            pageNumbersArr.push(i);
        }
        return pageNumbersArr;
    }

    const handleQAPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <header className='header'>
                <div className="header__upside" style={inlineStyle}>
                    <div className="container">
                        <Nav />
                        <div className="hero">

                            <div className="hero-upside">

                                <h6 className="hero-info">
                                    {/* number of episodes, like count, 播放次数, 评论数 */}
                                    <span className="time">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                                            <line x1="6" y1="6" x2="18" y2="6" />
                                            <line x1="6" y1="10" x2="18" y2="10" />
                                            <line x1="6" y1="14" x2="18" y2="14" />
                                            <line x1="6" y1="18" x2="18" y2="18" />
                                        </svg>
                                        {seriesSlides[currentSlide] && seriesSlides[currentSlide].series.data_status}
                                    </span>
                                    <span className="subtitle">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
                                        </svg>
                                        {seriesSlides[currentSlide] && seriesSlides[currentSlide].series.like_count} 人喜欢
                                    </span>
                                    <span className="ratings">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <polygon points="5 3 19 12 5 21 5 3" />
                                            <line x1="19" y1="20" x2="24" y2="20" />
                                            <line x1="19" y1="16" x2="22" y2="16" />
                                            <line x1="19" y1="12" x2="20" y2="12" />
                                        </svg>
                                        {seriesSlides[currentSlide] && seriesSlides[currentSlide].series.play_count} 次播放
                                    </span>
                                    <span className="year">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                                            <line x1="9" y1="10" x2="15" y2="10" />
                                            <line x1="9" y1="14" x2="15" y2="14" />
                                        </svg>
                                        {seriesSlides[currentSlide] && seriesSlides[currentSlide].series.comment_count} 次评论</span>
                                </h6>
                                <h1 className="hero-title">{seriesSlides[currentSlide] && seriesSlides[currentSlide].series.name}</h1>
                                <h1 className="hero-title">{seriesSlides[currentSlide] && seriesSlides[currentSlide].series.alias_name}</h1>
                                {/* <div className="hero-tags" >
                                    <span className="tag">Fantasy</span>
                                    <span className="tag">Sci-Fi</span>
                                </div> */}
                                <p className="hero-text invisible">{seriesSlides[currentSlide] && seriesSlides[currentSlide].series.description}</p>
                                <a href={`/seriesonplay/${seriesSlides[currentSlide] && seriesSlides[currentSlide].series.id}`} className="btn">立即播放</a>

                            </div>
                            <div className="hero-downside">
                                <div className="hero-downside--items">
                                    <a href="#" className="sliderIcon--prev" onClick={goToPreviousSlide}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                                    </svg></a>
                                    {
                                        seriesSlides.map((_, index) => (
                                            <a href="#"
                                                className={currentSlide === index ? "current sliderIcon-dot" : "sliderIcon-dot"}
                                                onClick={() => setCurrentSlide(index)}
                                                key={index}
                                            >
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                                                    <ellipse cx="5.51576" cy="4.5" rx="4.53822" ry="4.5" fill="white"
                                                        fillOpacity={currentSlide === index ? 0.18 : 1}
                                                    />
                                                </svg> */}
                                                {index + 1}
                                            </a>
                                        ))
                                    }
                                    <a href="#" className="sliderIcon--next" onClick={goToNextSlide}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                        // fillOpacity="0.18" 
                                        />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* —————————————————————————————— */}
                <section className="ontheair">
                    <div className="container">
                        <div className="ontheair__upside">
                            <h1 className="ontheair__upside--title">正在播放</h1>
                            {/* <a href="#" className="ontheair__upside--more">查看更多</a> */}
                        </div>
                        <div className="ontheair__items">
                            <span
                                className="ontheair__items--previous"
                                onClick={prevCarrouselPage}
                                style={currentCarrouselPage === 1 ? { cursor: 'not-allowed' } : {}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="90" viewBox="0 0 93 90" fill="none">
                                    <g filter="url(#filter0_d_173_1085)">
                                        <ellipse cx="26.3234" cy="25" rx="26.3234" ry="25" transform="matrix(-1 0 0 1 72.6465 16)" fill="white" />
                                    </g>
                                    <path d="M40.3485 40.2976L49.9194 32.2876C50.1408 32.1021 50.4363 32 50.7513 32C51.0664 32 51.3619 32.1021 51.5833 32.2876L52.2881 32.8773C52.7468 33.2616 52.7468 33.8862 52.2881 34.2699L44.2512 40.9963L52.297 47.7301C52.5184 47.9155 52.6406 48.1627 52.6406 48.4263C52.6406 48.6901 52.5184 48.9373 52.297 49.1228L51.5922 49.7124C51.3707 49.8979 51.0753 50 50.7603 50C50.4452 50 50.1497 49.8979 49.9283 49.7124L40.3485 41.695C40.1266 41.509 40.0047 41.2607 40.0054 40.9967C40.0047 40.7317 40.1266 40.4835 40.3485 40.2976Z" fill="#172639" />
                                    <defs>
                                        <filter id="filter0_d_173_1085" x="0" y="0" width="92.6465" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            {/* <feFlood fillopacity="0" result="BackgroundImageFix" /> */}
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_1085" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_1085" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            {
                                getCurrentPageData().map((item, index) => (
                                    <div className="ontheair__item" key={index}>
                                        <a href={`/seriesonplay/${seriesSlides[currentSlide].series.id}?video=${item.id}`}>
                                            <img 
                                                src={getImageUrl(item.poster_url) || 'https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg'} 
                                                alt={item.title}
                                            />
                                        </a>
                                        <div className="ontheair__item--tags">
                                            {/* <span className="tag">{item.language}</span>
                                                <span className="tag">{item.create_datetime.substring(0, 4)}</span> */}
                                        </div>
                                        <a href={`/seriesonplay/${seriesSlides[currentSlide].series.id}?video=${item.id}`}>
                                            <h2 className="on_the_air--item-title">
                                                {item.video_name.length > 15 ? item.video_name.substring(0, 15) + '...' : item.video_name}
                                            </h2>
                                        </a>
                                    </div>
                                ))
                            }
                            <span
                                className="ontheair__items--next"
                                onClick={nextCarrouselPage}
                                style={currentCarrouselPage === totalCarrouselPages ? { cursor: 'not-allowed' } : {}}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="93" height="90" viewBox="0 0 93 90" fill="none">
                                    <g filter="url(#filter0_d_173_1082)">
                                        <ellipse cx="46.6766" cy="41" rx="26.3234" ry="25" fill="white" />
                                    </g>
                                    <path d="M52.6513 40.2976L43.0804 32.2876C42.859 32.1021 42.5635 32 42.2484 32C41.9333 32 41.6378 32.1021 41.4165 32.2876L40.7116 32.8773C40.253 33.2616 40.253 33.8862 40.7116 34.2699L48.7486 40.9963L40.7027 47.7301C40.4814 47.9155 40.3591 48.1627 40.3591 48.4263C40.3591 48.6901 40.4814 48.9373 40.7027 49.1228L41.4075 49.7124C41.6291 49.8979 41.9244 50 42.2395 50C42.5546 50 42.8501 49.8979 43.0714 49.7124L52.6513 41.695C52.8732 41.509 52.995 41.2607 52.9943 40.9967C52.995 40.7317 52.8732 40.4835 52.6513 40.2976Z" fill="#172639" />
                                    <defs>
                                        <filter id="filter0_d_173_1082" x="0.353271" y="0" width="92.6467" height="90" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                            {/* <feFlood fillOpacity="0" result="BackgroundImageFix" /> */}
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset dy="4" />
                                            <feGaussianBlur stdDeviation="10" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_1082" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_1082" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>

                        </div>
                    </div>
                </section>
            </header >
            {/* ----------------------- */}
            <section className="selected">
                <div className="container">
                    <div className="selected__col--1st">
                        <h1 className="section-title">精选视频</h1>
                        <ul className="selected__tags">
                            <li><a href="#" className="tag">瘾症</a></li>
                            <li><a href="#" className="tag">亲子关系</a></li>
                            <li><a href="#" className="tag">失败婚姻</a></li>
                            <li><a href="#" className="tag">Anne Black</a></li>
                            <li><a href="#" className="tag">Warren French</a></li>
                            <li><a href="#" className="tag">Cecelia Munoz</a></li>
                            <li><a href="#" className="tag">Henrietta Stewart</a></li>
                            <li><a href="#" className="tag">Milton Richardson</a></li>
                        </ul>
                        <span>
                            {/* <a href="#" className="btn">查看更多</a> */}
                        </span>
                    </div>
                    <div className="selected__col--2nd">
                        <h1 className="section-title">改变的力量</h1>
                        <p className="section-text">
                            事业失败，情感挫折，严重瘾症，对生活失望，内心空虚，没有价值感……，如果你正遭遇这些问题困扰，请看信仰改变的力量。
                        </p>
                    </div>
                    <div className="selected__items">
                        {
                            getCurrentSelectedVideoPageData()
                                .map((item, index) => (
                                    <div className="selected__item" key={index}>
                                        <div className="selected__item--upside">
                                            <a href={`/seriesonplay/${item.associated_series_id}?video=${item.id}`}>
                                                <h1 className="title">{item.title}</h1>
                                                <div className="info__reviews">
                                                    <span>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="66" height="13" viewBox="0 0 66 13" fill="none">
                                                            <path d="M7.20724 0.550409L8.73907 3.6284C8.83559 3.82135 9.02084 3.95564 9.23723 3.98652L12.662 4.48048C13.2038 4.5592 13.4202 5.21987 13.0279 5.59806L10.5496 7.99377C10.3939 8.14504 10.3223 8.36115 10.3596 8.57417L10.945 11.9563C11.0368 12.4919 10.4702 12.8994 9.98602 12.6478L6.92236 11.0501C6.72932 10.9498 6.49893 10.9498 6.30745 11.0501L3.24223 12.6478C2.75653 12.9009 2.18988 12.4919 2.28328 11.9563L2.86862 8.57417C2.90598 8.36115 2.83437 8.14504 2.67869 7.99377L0.20037 5.59806C-0.191928 5.21833 0.0244588 4.55766 0.566203 4.48048L3.99102 3.98652C4.20585 3.95564 4.39266 3.82135 4.48918 3.6284L6.02101 0.550409C6.26386 0.062624 6.96439 0.062624 7.20724 0.550409Z" fill="#FFB800" />
                                                            <path d="M27.8015 0.550043L29.3333 3.62803C29.4298 3.82098 29.6151 3.95528 29.8315 3.98615L33.2563 4.48011C33.798 4.55884 34.0144 5.21951 33.6221 5.5977L31.1438 7.9934C30.9881 8.14468 30.9165 8.36078 30.9539 8.5738L31.5392 11.9559C31.6311 12.4915 31.0644 12.899 30.5803 12.6474L27.5166 11.0498C27.3236 10.9494 27.0932 10.9494 26.9017 11.0498L23.8365 12.6474C23.3508 12.9006 22.7841 12.4915 22.8775 11.9559L23.4629 8.5738C23.5002 8.36078 23.4286 8.14468 23.2729 7.9934L20.7946 5.5977C20.4023 5.21796 20.6187 4.55729 21.1604 4.48011L24.5853 3.98615C24.8001 3.95528 24.9869 3.82098 25.0834 3.62803L26.6152 0.550043C26.8581 0.0622578 27.5586 0.0622578 27.8015 0.550043Z" fill="#FFB800" />
                                                            <path d="M41.0861 0.550165L42.618 3.62815C42.7145 3.82111 42.8997 3.9554 43.1161 3.98627L46.541 4.48023C47.0827 4.55896 47.2991 5.21963 46.9068 5.59782L44.4285 7.99352C44.2728 8.1448 44.2012 8.3609 44.2385 8.57393L44.8239 11.956C44.9157 12.4916 44.3491 12.8992 43.8649 12.6476L40.8013 11.0499C40.6082 10.9496 40.3778 10.9496 40.1864 11.0499L37.1211 12.6476C36.6354 12.9007 36.0688 12.4916 36.1622 11.956L36.7475 8.57393C36.7849 8.3609 36.7133 8.1448 36.5576 7.99352L34.0793 5.59782C33.687 5.21809 33.9034 4.55741 34.4451 4.48023L37.8699 3.98627C38.0848 3.9554 38.2716 3.82111 38.3681 3.62815L39.8999 0.550165C40.1428 0.0623799 40.8433 0.0623799 41.0861 0.550165Z" fill="#FFB800" />
                                                            <path d="M59.0217 0.550287L60.5535 3.62827C60.65 3.82123 60.8353 3.95552 61.0517 3.9864L64.4765 4.48036C65.0182 4.55908 65.2346 5.21975 64.8423 5.59794L62.364 7.99364C62.2083 8.14492 62.1367 8.36103 62.1741 8.57405L62.7594 11.9561C62.8513 12.4918 62.2846 12.8993 61.8005 12.6477L58.7368 11.05C58.5438 10.9497 58.3134 10.9497 58.1219 11.05L55.0567 12.6477C54.571 12.9008 54.0043 12.4918 54.0977 11.9561L54.6831 8.57405C54.7204 8.36103 54.6488 8.14492 54.4931 7.99364L52.0148 5.59794C51.6225 5.21821 51.8389 4.55754 52.3807 4.48036L55.8055 3.9864C56.0203 3.95552 56.2071 3.82123 56.3036 3.62827L57.8355 0.550287C58.0783 0.0625019 58.7788 0.0625019 59.0217 0.550287Z" fill="#FFB800" />
                                                        </svg> */}
                                                        {convertMicrosecondsToTimeString(item.video_length)}
                                                    </span>
                                                </div>
                                            </a>
                                            <div className="info">
                                                <div className="info__love">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47" fill="none">
                                                        <rect x="0.331543" y="0.891602" width="46.4945" height="46.1029" rx="20" fill="#E4E4E4" />
                                                        <path d="M32.1919 15.854C31.1027 14.7026 29.6491 14.0692 28.0997 14.0692C26.5565 14.0692 25.1131 14.699 24.0349 15.8431L23.6144 16.2905L23.1939 15.8438C22.1122 14.6961 20.6654 14.0641 19.1181 14.0641C17.5721 14.0641 16.1226 14.6953 15.0354 15.8438C12.7887 18.2301 12.7873 22.1243 15.0354 24.5244L23.131 33.1135C23.2643 33.2544 23.4393 33.3256 23.6144 33.3256C23.7894 33.3256 23.9638 33.2544 24.0978 33.1135L32.1947 24.5222C34.438 22.1273 34.4373 18.2388 32.1919 15.854ZM30.7403 22.9844C30.6063 23.1268 30.4313 23.1987 30.2556 23.1987C30.0812 23.1987 29.9069 23.1283 29.7728 22.9873C29.5055 22.704 29.5041 22.2449 29.7708 21.9602C30.672 20.9977 30.6747 19.3778 29.7763 18.4233C29.5089 18.1393 29.5089 17.6802 29.7763 17.3961C30.0436 17.1121 30.4757 17.1121 30.7431 17.3961C32.1646 18.9063 32.1632 21.4662 30.7403 22.9844Z" fill="#FF055F" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                        <a 
                                            href={`/seriesonplay/${item.associated_series_id}?video=${item.id}`} 
                                            className="selected__item--lower"
                                        >
                                            <img src={item.poster_url || 'https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg'} alt={item.title} />
                                        </a>
                                    </div>
                                ))
                        }
                    </div>

                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleSelectedVideoPagePrev(currentSelectedVideoPage - 1)}
                            style={{ cursor: currentSelectedVideoPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalSelectedVideoPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentSelectedVideoPage === number ? 'current' : ''}`}
                                    onClick={() => setCurrentSelectedVideoPage(number)}
                                    style={{ cursor: currentSelectedVideoPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleSelectedVideoPageNext(currentSelectedVideoPage + 1)}
                            style={{ cursor: currentSelectedVideoPage === totalSelectedVideoPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>
            <section className="recommandations">
                <div className="container">
                    <div className="recommandations__upsdie">
                        <div className="recommandations__upsdie--title">
                            <h1 className="section-title">主题推荐</h1>
                            <span>
                                {/* <a href="#" className="btn">查看更多</a> */}
                            </span>
                        </div>
                        <ul className="recommandations__upsdie--tags">
                            {/* <li><a href="#">职业</a></li>
                            <li><a href="#">超自然</a></li>
                            <li><a href="#">人文</a></li>
                            <li><a href="#">科学与信仰</a></li>
                            <li><a href="#">home</a></li>
                            <li><a href="#">anyone</a></li>
                            <li><a href="#">rapidly</a></li>
                            <li><a href="#">pain</a></li> */}
                        </ul>
                    </div>
                    <div className="recommandations__items">
                        <div className="col">
                            {
                                getCurrentRecommendVideoPageData()
                                    .slice(0, 5)
                                    .map((item, index) => (
                                        <div className="recommandations__item" key={index}>
                                            <a href={`/seriesonplay/${item.id}`}>
                                                <img src={item.poster_url} alt="image" />
                                            </a>
                                            <div className="recommandations__item--downside">
                                                <a href={`/seriesonplay/${item.id}`}>
                                                    <h1 className="title">{item.name}</h1>
                                                </a>
                                                <p className="tags">
                                                    <span>{item.play_count} 次播放</span>
                                                    <span>{item.like_count} 次喜欢</span>
                                                </p>
                                                {/* <div className="more_tags">
                                                    <span>IMDb</span>
                                                    <span>HD</span>
                                                </div> */}
                                            </div>
                                        </div>
                                    ))
                            }

                        </div>
                        <div className="col">
                            {getCurrentRecommendVideoPageData()
                                .slice(5, 10)
                                .map((item, index) => (
                                    <div className="recommandations__item" key={index}>
                                        <a href={`/seriesonplay/${item.id}`} className="selected__item--lower">
                                            <img src={item.poster_url} alt="image" />   
                                        </a>
                                        <div className="recommandations__item--downside">
                                            <a href={`/seriesonplay/${item.id}`}>
                                                <h1 className="title">{item.name}</h1>
                                            </a>
                                            {/* <div className="inner__html" dangerouslySetInnerHTML={{ __html: item.description}} />  */}
                                            <p className="tags">
                                                <span>{item.play_count} 次播放</span>
                                                <span>{item.like_count} 次喜欢</span>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleRecommendVideoPagePrev(currentRecommendVideoPage - 1)}
                            style={{ cursor: currentRecommendVideoPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalRecommendVideoPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentRecommendVideoPage === number ? 'current' : ''}`}
                                    onClick={() => setCurrentRecommendVideoPage(number)}
                                    style={{ cursor: currentRecommendVideoPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleRecommendVideoPageNext(currentRecommendVideoPage + 1)}
                            style={{ cursor: currentRecommendVideoPage === totalRecommendVideoPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>

                </div>
            </section>
            <section className="series">
                <div className="container">
                    <div className="series__upside">
                        <div className="series__upside--title">
                            <h1>
                                系列片
                            </h1>
                            <span>
                                {/* <a href="#" className="btn">查看更多</a> */}
                            </span>
                        </div>
                        <ul className="series__upside--tags">
                            {/* <li><a href="#">全部</a></li>
                            <li><a href="#">见证专辑</a></li>
                            <li><a href="#">人物专访</a></li>
                            <li><a href="#">record</a></li>
                            <li><a href="#">ability</a></li>
                            <li><a href="#">well</a></li>
                            <li><a href="#">affect</a></li>
                            <li><a href="#">音乐</a></li> */}
                        </ul>
                    </div>
                    <div className="series__section">
                        <div className="series__items">
                            {
                                seriesSectionVideos?.map((item) => (
                                    <div className="series__item" key={item.id}>
                                        <a href={`/seriesonplay/${item.id}`} className="series__item--poster">
                                            <img src={item.poster_url} alt={item.name} />
                                        </a>
                                        <div className="content">
                                            <div className="tags">
                                                <div className="tags__pre"></div>
                                                <span className="tags__after"></span>
                                            </div>
                                            <h1 className="title">{item.name}</h1>
                                            <div className="intro__text">{item.description}</div>
                                            <a href={`/seriesonplay/${item.id}`} className="btn">立即播放</a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="paging">
                            {/* <span>
                                <img src={btnPrev} alt="button previous" />
                            </span>
                            <span>
                                <img src={btnNext} alt="button next" />
                            </span> */}
                        </div>
                    </div>
                </div>
            </section>
            <section className="evangelize">
                <div className="container">
                    <h1 className="evangelize__title">传递福音</h1>
                    <div className="evangelize__body">
                        <div className="evangelize__left">
                            <div className="evangelize__left--upside">
                                <div className="left">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="65" viewBox="0 0 69 65" fill="none">
                                        <g clipPath="url(#clip0_87_2411)">
                                            <path d="M48.1095 0.487793H13.209C9.83649 0.487793 7.09375 3.10328 7.09375 6.31307V58.7402C7.09375 61.9529 9.83649 64.5655 13.2092 64.5655H56.0166C59.3893 64.5655 62.132 61.9529 62.132 58.7402V13.8451L48.1095 0.487793ZM22.3821 50.0023V26.7014L46.8436 38.3605L22.3821 50.0023ZM43.7859 17.9635V3.40037L59.0743 17.9635H43.7859Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_87_2411">
                                                <rect width="67.269" height="64.0776" fill="white" transform="translate(0.978271 0.487793)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div className="left__title">
                                        <h1>精选短片</h1>
                                        <div className="left__title--info">
                                            <span>为传福音制作的创意短片，从不同的角度让你的朋友们了解到福音的奥秘</span>
                                            {/* <span>英文</span> */}
                                            {/* <span>2小时30分钟</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="right__info">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="97" height="20" viewBox="0 0 97 20" fill="none">
                                            <path d="M11.5004 1.29203L13.7506 5.81334C13.8923 6.09677 14.1645 6.29404 14.4823 6.33939L19.5131 7.06497C20.3089 7.18062 20.6267 8.15109 20.0505 8.70661L16.41 12.2257C16.1813 12.4479 16.0761 12.7654 16.131 13.0783L16.9908 18.0463C17.1258 18.8331 16.2934 19.4317 15.5822 19.0621L11.082 16.7153C10.7984 16.5679 10.46 16.5679 10.1787 16.7153L5.67615 19.0621C4.96269 19.434 4.13033 18.8331 4.26753 18.0463L5.12734 13.0783C5.18222 12.7654 5.07703 12.4479 4.84836 12.2257L1.2079 8.70661C0.631648 8.14882 0.949502 7.17835 1.74528 7.06497L6.77606 6.33939C7.09163 6.29404 7.36604 6.09677 7.50781 5.81334L9.75794 1.29203C10.1147 0.575509 11.1437 0.575509 11.5004 1.29203Z" fill="#FFB800" />
                                            <path d="M41.7482 1.29203L43.9984 5.81334C44.1401 6.09677 44.4123 6.29404 44.7301 6.33939L49.7609 7.06497C50.5567 7.18062 50.8745 8.15109 50.2983 8.70661L46.6578 12.2257C46.4291 12.4479 46.324 12.7654 46.3788 13.0783L47.2386 18.0463C47.3736 18.8331 46.5412 19.4317 45.83 19.0621L41.3298 16.7153C41.0462 16.5679 40.7078 16.5679 40.4265 16.7153L35.924 19.0621C35.2105 19.434 34.3781 18.8331 34.5153 18.0463L35.3751 13.0783C35.43 12.7654 35.3248 12.4479 35.0962 12.2257L31.4557 8.70661C30.8795 8.14882 31.1973 7.17835 31.9931 7.06497L37.0239 6.33939C37.3394 6.29404 37.6138 6.09677 37.7556 5.81334L40.0057 1.29203C40.3625 0.575509 41.3915 0.575509 41.7482 1.29203Z" fill="#FFB800" />
                                            <path d="M61.2643 1.29203L63.5145 5.81334C63.6562 6.09677 63.9284 6.29404 64.2462 6.33939L69.277 7.06497C70.0728 7.18062 70.3906 8.15109 69.8144 8.70661L66.1739 12.2257C65.9453 12.4479 65.8401 12.7654 65.8949 13.0783L66.7548 18.0463C66.8897 18.8331 66.0573 19.4317 65.3461 19.0621L60.8459 16.7153C60.5623 16.5679 60.2239 16.5679 59.9426 16.7153L55.4401 19.0621C54.7266 19.434 53.8942 18.8331 54.0314 18.0463L54.8913 13.0783C54.9461 12.7654 54.8409 12.4479 54.6123 12.2257L50.9718 8.70661C50.3956 8.14882 50.7134 7.17835 51.5092 7.06497L56.54 6.33939C56.8555 6.29404 57.13 6.09677 57.2717 5.81334L59.5219 1.29203C59.8786 0.575509 60.9076 0.575509 61.2643 1.29203Z" fill="#FFB800" />
                                            <path d="M87.6096 1.29203L89.8597 5.81334C90.0015 6.09677 90.2736 6.29404 90.5914 6.33939L95.6222 7.06497C96.418 7.18062 96.7358 8.15109 96.1596 8.70661L92.5191 12.2257C92.2905 12.4479 92.1853 12.7654 92.2402 13.0783L93.1 18.0463C93.2349 18.8331 92.4025 19.4317 91.6913 19.0621L87.1911 16.7153C86.9075 16.5679 86.5691 16.5679 86.2878 16.7153L81.7853 19.0621C81.0718 19.434 80.2395 18.8331 80.3767 18.0463L81.2365 13.0783C81.2913 12.7654 81.1862 12.4479 80.9575 12.2257L77.317 8.70661C76.7408 8.14882 77.0586 7.17835 77.8544 7.06497L82.8852 6.33939C83.2008 6.29404 83.4752 6.09677 83.6169 5.81334L85.8671 1.29203C86.2238 0.575509 87.2528 0.575509 87.6096 1.29203Z" fill="#FFB800" />
                                        </svg> */}
                                        {/* <span>345 reviews</span> */}
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
                                        <rect x="0.604248" y="0.917358" width="68.2966" height="67.7214" rx="20" fill="#E4E4E4" />
                                        <path d="M47.4048 22.8955C45.8048 21.2043 43.6695 20.2738 41.3936 20.2738C39.1268 20.2738 37.0066 21.1989 35.4227 22.8795L34.805 23.5368L34.1873 22.8806C32.5984 21.1947 30.4732 20.2664 28.2003 20.2664C25.9295 20.2664 23.8002 21.1936 22.2033 22.8806C18.903 26.3858 18.901 32.1062 22.2033 35.6317L34.0949 48.2484C34.2908 48.4554 34.5479 48.56 34.805 48.56C35.0621 48.56 35.3183 48.4554 35.5151 48.2484L47.4088 35.6285C50.7041 32.1105 50.7031 26.3986 47.4048 22.8955ZM45.2725 33.3696C45.0756 33.5787 44.8185 33.6844 44.5604 33.6844C44.3043 33.6844 44.0482 33.5809 43.8513 33.3739C43.4586 32.9577 43.4566 32.2834 43.8483 31.8651C45.1721 30.4512 45.1761 28.0718 43.8563 26.6697C43.4636 26.2524 43.4636 25.5781 43.8563 25.1609C44.249 24.7437 44.8838 24.7437 45.2765 25.1609C47.3646 27.3792 47.3626 31.1395 45.2725 33.3696Z" fill="#FF055F" />
                                    </svg>
                                </div>
                            </div>
                            {
                                evangellizeLeftVideo && (
                                    <div 
                                        className="evangelize__left--video"
                                        onClick={() => {
                                            navigate(`/seriesonplay/${evangellizeLeftVideo.associated_series_id}?video=${evangellizeLeftVideo.id}`);
                                        }}
                                    >
                                        <img src={evangellizeLeftVideo.poster_url} alt="image of evangelize" />
                                    </div>
                                )
                            }
                        </div>
                        <div className="evangelize__right">
                            {
                                setEvangellizeRightVideo1 && (
                                    <div 
                                        className="evangelize__right--video"
                                        onClick={() => {
                                            navigate(`/seriesonplay/${evangellizeRightVideo1.associated_series_id}?video=${evangellizeRightVideo1.id}`);
                                        }}
                                    >
                                        <img src={evangellizeRightVideo1?.poster_url} alt="image 1 of evangelize" />
                                    </div>
                                )
                            }
                            {
                                setEvangellizeRightVideo2 && (
                                    <div 
                                        className="evangelize__right--video"
                                        onClick={() => {
                                            navigate(`/seriesonplay/${evangellizeRightVideo2.associated_series_id}?video=${evangellizeRightVideo2.id}`);
                                        }}
                                    >
                                        <img src={evangellizeRightVideo2?.poster_url} alt="image 2 of evangelize" />
                                    </div>
                                )
                            }
                            {
                                setEvangellizeRightVideo3 && (
                                    <div 
                                        className="evangelize__right--video"
                                        onClick={() => {
                                            navigate(`/seriesonplay/${evangellizeRightVideo3.associated_series_id}?video=${evangellizeRightVideo3.id}`);
                                        }}
                                    >
                                        <img src={evangellizeRightVideo3?.poster_url} alt="image 3 of evangelize" />
                                    </div>
                                )
                            }
                            {
                                setEvangellizeRightVideo4 && (
                                    <div 
                                        className="evangelize__right--video"
                                        onClick={() => {
                                            navigate(`/seriesonplay/${evangellizeRightVideo4.associated_series_id}?video=${evangellizeRightVideo4.id}`);
                                        }}
                                    >
                                        <img src={evangellizeRightVideo4?.poster_url} alt="image 4 of evangelize" />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
            <section className="answered">
                <div className="container">
                    <h1 className="answered__title">信仰问答</h1>
                    <p className="answered__subtitle">深度探讨信仰的核心问题，解答关于信仰生活中的疑惑与挑战，帮助您更好地理解和实践信仰的真理 </p>
                    <div className="answered__items">

                        {currentQuestions && currentQuestions
                            .map((question, index) => {
                                return (
                                    <div className={`answered__item answered__item--${index + 1}`}
                                        key={question.id}
                                        onClick={() => {
                                            if (selectedQuestion === question.id) {
                                                setSelectedQuestion(0)
                                            } else {
                                                setSelectedQuestion(question.id)
                                            }
                                        }}
                                    >
                                        <div className="answered__item--title">
                                            <h1>{question.title}</h1>
                                            <span>
                                                {question.id === selectedQuestion ?
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        // onClick={() => setSelectedQuestion(0)}
                                                        width="52" height="51" viewBox="0 0 52 51" fill="none">
                                                        <ellipse cx="25.9545" cy="25.3309" rx="25.0941" ry="25.3309" fill="#DB5443" />
                                                        <path d="M17.7749 22.2212L25.9543 30.4405L34.1338 22.2212" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg> :
                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                        // onClick={() => setSelectedQuestion(question.id)}
                                                        width="84" height="83" viewBox="0 0 84 83" fill="none">
                                                        <g filter="url(#filter0_d_173_553)">
                                                            <ellipse cx="41.9545" cy="36.3309" rx="25.0941" ry="25.3309" fill="#333333" />
                                                        </g>
                                                        <path d="M38.8735 44.5874L47.016 36.3308L38.8735 28.0742" stroke="#F2F2F2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <defs>
                                                            <filter id="filter0_d_173_553" x="0.860352" y="0" width="82.1882" height="82.6616" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood fillOpacity="0" result="BackgroundImageFix" />
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                <feOffset dy="5" />
                                                                <feGaussianBlur stdDeviation="8" />
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0.0323264 0 0 0 0 0.0598209 0 0 0 0 0.204167 0 0 0 0.06 0" />
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_173_553" />
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_173_553" result="shape" />
                                                            </filter>
                                                        </defs>
                                                    </svg>
                                                }
                                            </span>
                                        </div>
                                        <div className="answered__item--content">
                                            {question.id === selectedQuestion ? <div dangerouslySetInnerHTML={{ __html: question.content }} /> : ''}
                                        </div>
                                    </div>
                                )
                            })}

                    </div>
                    <div className="answered_pagination">
                        <span className="sliderIcon--prev"
                            onClick={() => handleQAPageChange(currentPage - 1)}
                            style={{ cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.1023 3C17.1151 3 21.1788 7.02944 21.1788 12C21.1788 16.9706 17.1151 21 12.1023 21C7.08954 21 3.02588 16.9706 3.02588 12C3.02588 7.02944 7.08954 3 12.1023 3ZM13.0802 7.71967L8.76354 12L13.0802 16.2803L14.1499 15.2197L10.9035 12L14.1499 8.78033L13.0802 7.71967Z" fill="white" />
                            </svg>
                        </span>

                        {
                            pageNumbers(totalPages).map(number => (
                                <span key={number}
                                    // if the current page is equal to the number, add bold_nubmer class to style it
                                    className={`sliderIcon-dot ${currentPage === number ? 'current' : ''}`}
                                    onClick={() => handleQAPageChange(number)}
                                    style={{ cursor: currentPage === number ? 'not-allowed' : 'pointer' }}
                                >
                                    {number}
                                </span>
                            ))
                        }

                        <span className="sliderIcon--next"
                            onClick={() => handleQAPageChange(currentPage + 1)}
                            style={{ cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 25 24" fill="none">
                                <path d="M12.292 3C7.27918 3 3.21552 7.02944 3.21552 12C3.21552 16.9706 7.27918 21 12.292 21C17.3047 21 21.3684 16.9706 21.3684 12C21.3684 7.02944 17.3047 3 12.292 3ZM11.3141 7.71967L15.6307 12L11.3141 16.2803L10.2444 15.2197L13.4908 12L10.2444 8.78033L11.3141 7.71967Z" fill="white"
                                // fillOpacity="0.18" 
                                />
                            </svg>
                        </span>
                    </div>
                </div>
            </section>

            {/* ----------------------- */}

            <Footer />
        </>
    );
}

export default Home;
