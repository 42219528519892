import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchSeries = createAsyncThunk(
	'series/fetchSeries',
	async () => {
		const response = await axios.get(`/wp-json/hello-world/v1/slides`);
		return response.data;
	}
);

export const fetchSelectedVideos = createAsyncThunk(
	'series/fetchSelectedVideos',
	async () => {
		const response = await axios.get(`/wp-json/hello-world/v1/selected`);
		return response.data;
	}
);

// fetchRecommendVideos
export const fetchRecommendVideos = createAsyncThunk(
	'series/fetchRecommendVideos',
	async () => {
		const response = await axios.get(`/wp-json/hello-world/v1/recommend`);
		return response.data;
	}
);

// fetchFrequentlyAskedQuestions
export const fetchFrequentlyAskedQuestions = createAsyncThunk(
	'series/fetchFrequentlyAskedQuestions',
	async () => {
		const response = await axios.get(`/wp-json/hello-world/v1/faq`);
		return response.data;
	}
);	

// fetchSeriesSectionVideos
export const fetchSeriesSectionVideos = createAsyncThunk(
	'series/fetchSeriesSectionVideos',
	async () => {
		const response = await axios.get(`/wp-json/hello-world/v1/series_section_videos`);
		return response.data;
	}
);

export const seriesSlice = createSlice({
	name: "series",
	initialState: {
		seriesSlides: [],
		seriesOnPlay:{}, // {series_id: 1, video_id: 1}
		selectedVideos: [],
		recommendVideos: [],
		frequentlyAskedQuestions: [],
		isReady: false,
		error: null,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder

			// fetchSeries
			.addCase(fetchSeries.fulfilled, (state, action) => {
				state.seriesSlides = action.payload;
				state.isReady = true
			})
			.addCase(fetchSeries.rejected, (state, action) => {
				state.isReady = false;
				state.error = action.error.message
			})
			.addCase(fetchSeries.pending, (state, action) => {
				state.isReady = false;
			})

			// fetchSelectedVideos
			.addCase(fetchSelectedVideos.fulfilled, (state, action) => {
				state.selectedVideos = action.payload;
				state.isReady = true
			})
			.addCase(fetchSelectedVideos.rejected, (state, action) => {
				state.isReady = false;
				state.error = action.error.message
			})
			.addCase(fetchSelectedVideos.pending, (state, action) => {
				state.isReady = false;
			})

			// fetchRecommendVideos
			.addCase(fetchRecommendVideos.fulfilled, (state, action) => {
				state.recommendVideos = action.payload;
				state.isReady = true
			})
			.addCase(fetchRecommendVideos.rejected, (state, action) => {
				state.isReady = false;
				state.error = action.error.message
			})
			.addCase(fetchRecommendVideos.pending, (state, action) => {
				state.isReady = false;
			})

			// fetchFrequentlyAskedQuestions
			.addCase(fetchFrequentlyAskedQuestions.fulfilled, (state, action) => {
				state.frequentlyAskedQuestions = action.payload;
				state.isReady = true
			})
			.addCase(fetchFrequentlyAskedQuestions.rejected, (state, action) => {
				state.isReady = false;
				state.error = action.error.message
			})
			.addCase(fetchFrequentlyAskedQuestions.pending, (state, action) => {
				state.isReady = false;
			})

			// fetchSeriesSectionVideos
			.addCase(fetchSeriesSectionVideos.fulfilled, (state, action) => {
				state.seriesSectionVideos = action.payload;
				state.isReady = true
			})
			.addCase(fetchSeriesSectionVideos.rejected, (state, action) => {
				state.isReady = false;
				state.error = action.error.message
			})
			.addCase(fetchSeriesSectionVideos.pending, (state, action) => {
				state.isReady = false;
			})

			
	},
});

export const {  } = seriesSlice.actions;
export default seriesSlice.reducer;