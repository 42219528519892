// Component Name: Series
import { useState, useEffect } from 'react'
import Nav from './Nav'
import Footer from './Footer'
// import ImgTheme1 from '../static/series-themes-image-1.png'
// import ImgTheme2 from '../static/series-themes-image-2.png'
import '../css/Series.scss'
import axios from 'axios'
import { fetchRecommendVideos } from '../store/series'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

function Series() {
    const navigate = useNavigate();

    const [pageHero, setPageHero] = useState(null);
    const [seriesTypes, setSeriesTypes] = useState([]);

    const [currentSeriesTypeID, setCurrentSeriesTypeID] = useState(1);
    const [filteredSeries, setFilteredSeries] = useState([]);

    const dispatch = useDispatch();
    const { recommendVideos } = useSelector((state) => state.series);

    useEffect(() => {
        // if currentSeriesTypeID is 0, set filteredSeries to all series    
        if (currentSeriesTypeID === 0) {
            setFilteredSeries(recommendVideos);
        } else {
            const newFilteredSeries = recommendVideos.filter(series => series.type_id == currentSeriesTypeID);
            setFilteredSeries(newFilteredSeries);
        }
    }, [recommendVideos, currentSeriesTypeID]);

    useEffect(() => {
        document.title = '系列 - 7G'
        document.querySelector('#root').className = 'series'

        dispatch(fetchRecommendVideos());

        axios.get('/wp-json/hello-world/v1/get-series-types')
            .then(response => setSeriesTypes(response.data))
            .catch(error => console.error('Error fetching series types:', error));

        // get series by id
        axios.get('/wp-json/hello-world/v1/series/759')
            .then(response => setPageHero(response.data))
            .catch(error => console.error('Error fetching series by id:', error));
    }, [])

    const handleSeriesTypeClick = (id) => {
        setCurrentSeriesTypeID(id);
    }

    useEffect(() => {
        if (seriesTypes.length > 0) {
            // Check if current ID exists in seriesTypes
            const exists = seriesTypes.some(type => type.id === currentSeriesTypeID);
            if (!exists) {
                // If not, set to first available ID
                setCurrentSeriesTypeID(seriesTypes[0].id);
            }
        }
    }, [seriesTypes]);

    // Replace the direct access with a null check
    const backgroundImage = pageHero ? `url(${pageHero.cover_url}) lightgray 50% 50% / cover no-repeat` : 'lightgray';

    return (
        <>
            <header className='header' style={{ background: backgroundImage }}>
                <div className='container'>
                    <Nav />
                    {
                        pageHero && (
                            <div className='header__hero'>
                                <div className='header__hero--intro invisible'>
                                    <span className='time'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' viewBox='0 0 16 18' fill='none' >
                                            <path d='M5.14892 10.2451L7.37977 8.49206V4.92103C7.37977 4.56196 7.65677 4.27173 7.99945 4.27173C8.34214 4.27173 8.61914 4.56196 8.61914 4.92103V8.81674C8.61914 9.02126 8.52742 9.21411 8.37127 9.33618L5.89258 11.284C5.78105 11.3717 5.65089 11.4139 5.52139 11.4139C5.33239 11.4139 5.14648 11.3249 5.02502 11.1535C4.81923 10.8672 4.87502 10.4601 5.14892 10.2451Z' fill='white' />
                                            <path d='M8 0.38208C12.4115 0.38208 16 4.14208 16 8.76433C16 13.3866 12.4115 17.1466 8 17.1466C3.58853 17.1466 0 13.3866 0 8.76433C0 4.14208 3.58853 0.38208 8 0.38208ZM8 15.848C11.7273 15.848 14.7607 12.6698 14.7607 8.76433C14.7607 4.85889 11.7273 1.68064 8 1.68064C4.27203 1.68064 1.23934 4.85889 1.23934 8.76433C1.23934 12.6698 4.27266 15.848 8 15.848Z' fill='white' />
                                        </svg>
                                        5 集
                                    </span>
                                    <span className='subtitle'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' viewBox='0 0 20 18' fill='none' >
                                            <path d='M17.0706 2.44081C16.8418 2.2362 16.4708 2.2362 16.2419 2.44081C16.0131 2.64542 16.0131 2.97723 16.2419 3.18188C19.684 6.2602 19.684 11.2691 16.2419 14.3475C16.0131 14.5521 16.0131 14.8839 16.2419 15.0885C16.3563 15.1908 16.5063 15.242 16.6562 15.242C16.8062 15.242 16.9562 15.1908 17.0705 15.0885C18.9593 13.3994 19.9995 11.1535 19.9995 8.76465C19.9995 6.3758 18.9593 4.12996 17.0706 2.44081Z' fill='white' />
                                            <path d='M15.2952 4.02821C15.0664 3.8236 14.6954 3.8236 14.4665 4.02821C14.2377 4.23286 14.2377 4.56463 14.4665 4.76928C15.6599 5.83646 16.3171 7.25532 16.3171 8.76452C16.3171 10.2737 15.6599 11.6926 14.4665 12.7597C14.2378 12.9643 14.2377 13.2961 14.4665 13.5008C14.581 13.6031 14.7309 13.6543 14.8808 13.6543C15.0308 13.6543 15.1808 13.6031 15.2951 13.5008C16.7098 12.2357 17.4889 10.5537 17.4889 8.76452C17.4889 6.97536 16.7098 5.29333 15.2952 4.02821Z' fill='white' />
                                            <path d='M12.2644 0.517606C11.8241 0.299615 11.2651 0.346253 10.7304 0.645571L4.18324 4.31131H2.15492C0.96668 4.31131 0 5.17584 0 6.23851V11.2901C0 12.3528 0.96668 13.2173 2.15492 13.2173H4.18324L10.7303 16.8831C11.0427 17.058 11.3634 17.1466 11.6639 17.1466C11.8777 17.1466 12.0813 17.1017 12.2644 17.011C12.7046 16.793 12.9571 16.3445 12.9571 15.7803V1.74828C12.9571 1.18419 12.7046 0.735632 12.2644 0.517606ZM3.76559 12.1693H2.15492C1.61285 12.1693 1.17187 11.7749 1.17187 11.2901V6.23851C1.17187 5.75372 1.61285 5.35935 2.15492 5.35935H3.76559V12.1693ZM11.7852 15.7803C11.7852 16.0023 11.7143 16.0855 11.6967 16.0941C11.6791 16.1028 11.5627 16.1126 11.3522 15.9947L4.93746 12.4031V5.12553L11.3522 1.53385C11.5627 1.41598 11.6791 1.4258 11.6967 1.43443C11.7142 1.44313 11.7852 1.5263 11.7852 1.74824V15.7803Z' fill='white' />
                                        </svg>
                                        {' '}中文
                                    </span>
                                    {/* <span className='ratings'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none' >
                                            <path d='M14.8406 6.48542L11.5537 9.77367L12.3299 14.418C12.3637 14.621 12.2824 14.8263 12.1199 14.9476C12.0281 15.0164 11.9188 15.051 11.8096 15.051C11.7257 15.051 11.6413 15.0304 11.5642 14.9887L7.5 12.796L3.4363 14.9882C3.259 15.0846 3.04318 15.0689 2.88065 14.947C2.71813 14.8257 2.63686 14.6205 2.67063 14.4174L3.44686 9.77313L0.159386 6.48542C0.0158556 6.34137 -0.0363851 6.12583 0.0258816 5.93034C0.0881483 5.73484 0.253314 5.59133 0.45225 5.56154L4.99508 4.88461L7.02667 0.659486C7.2045 0.289611 7.7955 0.289611 7.97333 0.659486L10.0049 4.88461L14.5477 5.56154C14.7467 5.59133 14.9119 5.7343 14.9741 5.93034C15.0364 6.12638 14.9841 6.34083 14.8406 6.48542Z' fill='#E5084A' />
                                        </svg>
                                        9.3
                                    </span>
                                    <span className='year'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' viewBox='0 0 16 18' fill='none' >
                                            <path d='M2.20308 17.1466H13.7969C15.0123 17.1466 16 16.15 16 14.9237V3.84673C16 2.62044 15.0123 1.62387 13.7969 1.62387H12.9231V1.00298C12.9231 0.661473 12.6461 0.38208 12.3077 0.38208C11.9692 0.38208 11.6923 0.661473 11.6923 1.00298V1.62387H4.30771V1.00298C4.30771 0.661473 4.03075 0.38208 3.69229 0.38208C3.35382 0.38208 3.07692 0.661473 3.07692 1.00298V1.62387H2.20308C0.9877 1.62387 0 2.62044 0 3.84673V14.9237C0 16.15 0.9877 17.1466 2.20308 17.1466ZM1.2308 3.84673C1.2308 3.30655 1.66771 2.86572 2.20308 2.86572H3.07692V3.48661C3.07692 3.82812 3.35382 4.10751 3.69229 4.10751C4.03075 4.10751 4.30766 3.82812 4.30766 3.48661V2.86572H11.6923V3.48661C11.6923 3.82812 11.9692 4.10751 12.3077 4.10751C12.6461 4.10751 12.923 3.82812 12.923 3.48661V2.86572H13.7969C14.3322 2.86572 14.7692 3.30655 14.7692 3.84673V5.6598H1.2308V3.84673ZM1.2308 6.90159H14.7693V14.9237C14.7693 15.4639 14.3323 15.9047 13.797 15.9047H2.20308C1.66771 15.9047 1.2308 15.4639 1.2308 14.9237V6.90159Z' fill='white' />
                                        </svg>
                                        2023
                                    </span> */}
                                </div>
                                <h1 className='header__hero--title'
                                    onClick={() => {
                                        navigate(`/seriesonplay/${pageHero.id}`);
                                    }}
                                >
                                    {pageHero.name}
                                </h1>
                                {/* text with html element */}
                                <div className='header__hero--description'
                                    dangerouslySetInnerHTML={{ __html: pageHero.description }}
                                />
                                <div className='header__hero--tags invisible'>
                                    <span className='tag'>抑郁</span>
                                    <span className='tag'>徐理强</span>
                                </div>
                                <div className='header__hero--cta'>
                                    <button className='btn btn--primary'
                                        onClick={() => {
                                            navigate(`/seriesonplay/${pageHero.id}`);
                                        }}
                                    >立即播放</button>
                                    {/* <button className='btn btn--secondary'>加入收藏</button> */}
                                </div>
                                <div className='header__hero--slides'>
                                    {/* <a href='#' className='slides__prev'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='25' height='26' viewBox='0 0 25 26' fill='none' >
                                            <path d='M12.1018 3.88403C17.1146 3.88403 21.1783 8.106 21.1783 13.3141C21.1783 18.5221 17.1146 22.7441 12.1018 22.7441C7.08905 22.7441 3.02539 18.5221 3.02539 13.3141C3.02539 8.106 7.08905 3.88403 12.1018 3.88403ZM13.0797 8.82922L8.76305 13.3141L13.0797 17.7989L14.1494 16.6876L10.903 13.3141L14.1494 9.94056L13.0797 8.82922Z' fill='white' />
                                        </svg>
                                    </a>
                                    <a href='#' className='slides__dot'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' >
                                            <ellipse cx='4.87807' cy='4.79021' rx='4.53822' ry='4.71502' fill='white' fillOpacity='0.1' />
                                        </svg>
                                    </a>
                                    <a href='#' className='slides__dot'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' >
                                            <ellipse cx='4.87807' cy='4.79021' rx='4.53822' ry='4.71502' fill='white' fillOpacity='0.1' />
                                        </svg>
                                    </a>
                                    <a href='#' className='slides__dot'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none' >
                                            <ellipse cx='4.87807' cy='4.79021' rx='4.53822' ry='4.71502' fill='white' fillOpacity='0.1' />
                                        </svg>
                                    </a>
                                    <a href='#' className='slides__dot'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none' >
                                            <ellipse cx='5.51478' cy='4.79021' rx='4.53822' ry='4.71502' fill='white' />
                                        </svg>
                                    </a>
                                    <a href='#' className='slides__next'>
                                        <svg xmlns='http://www.w3.org/2000/svg' width='25' height='26' viewBox='0 0 25 26' fill='none' >
                                            <path d='M12.2917 3.88403C7.27894 3.88403 3.21527 8.106 3.21527 13.3141C3.21527 18.5221 7.27894 22.7441 12.2917 22.7441C17.3045 22.7441 21.3682 18.5221 21.3682 13.3141C21.3682 8.106 17.3045 3.88403 12.2917 3.88403ZM11.3138 8.82922L15.6305 13.3141L11.3138 17.7989L10.2441 16.6876L13.4906 13.3141L10.2441 9.94056L11.3138 8.82922Z' fill='white' fillOpacity='0.18' />
                                        </svg>
                                    </a> */}
                                </div>
                            </div>
                        )
                    }
                </div>
            </header>
            <section className='content'>
                <div className="container">
                    <div className='title'>
                        <h1 className='title__title'>主题视频</h1>
                        <ul className='title__tags'>
                            {/* 如果currentSeriesTypeID为0，则显示“全部” */}
                            <li className='title__tags--item' key={0}> {' '}
                                <a href='#'
                                    className={`title__tags--link ${currentSeriesTypeID === 0 ? 'bold' : ''}`}
                                    onClick={() => handleSeriesTypeClick(0)}
                                > 全部
                                </a>{' '}
                            </li>   
                            {seriesTypes.map((seriesType, index) => (
                                <li className='title__tags--item' key={index + 1}> {' '}
                                    <a href='#'
                                        className={`title__tags--link ${currentSeriesTypeID === seriesType.id ? 'bold' : ''}`}
                                        onClick={() => handleSeriesTypeClick(seriesType.id)}
                                    > {seriesType.name}
                                    </a>{' '}
                                </li>
                            ))}
                        </ul>
                        {/* <div className='content__title--status'>
                            状态：
                            <span className='status'> <a href="#" className="status__link">全部</a></span>
                            <span className='status'> <a href="#" className="status__link">已完结</a></span>
                            <span className='status'> <a href="#" className="status__link">更新中</a></span>
                        </div> */}
                    </div>
                    <div className='content__cards'>
                        {
                            // 如果filteredSeries为空，则显示“当前主题没有视频”
                            filteredSeries.length === 0 ? (
                                <div className='card'>
                                    <div className='card__content'>
                                        <h1>当前主题没有视频</h1>
                                    </div>
                                </div>
                            ) : (
                                filteredSeries.map((series, index) => (
                                    <div className='card' key={index + 1}>
                                        <div className='card__content'>
                                            <a href={`/seriesonplay/${series.id}`}>
                                                <h1>{series.name}</h1>
                                            </a>
                                            <div className='card__content--info'>
                                                <span>
                                                    {series.play_count} 次播放
                                                </span>
                                                <svg xmlns='http://www.w3.org/2000/svg' width='48' height='49' viewBox='0 0 48 49' fill='none' >
                                                    <rect x='0.105469' y='0.819092' width='47.8905' height='47.4871' rx='20' fill='#E4E4E4' />
                                                    <path d='M32.9224 16.2307C31.8005 15.0448 30.3033 14.3923 28.7074 14.3923C27.1178 14.3923 25.6311 15.041 24.5205 16.2195L24.0873 16.6804L23.6542 16.2202C22.5401 15.038 21.0498 14.3871 19.4561 14.3871C17.8637 14.3871 16.3706 15.0373 15.2509 16.2202C12.9366 18.6781 12.9352 22.6894 15.2509 25.1615L23.5894 34.0084C23.7268 34.1536 23.9071 34.2269 24.0873 34.2269C24.2676 34.2269 24.4472 34.1536 24.5853 34.0084L32.9253 25.1592C35.236 22.6923 35.2353 18.6871 32.9224 16.2307ZM31.4273 23.5752C31.2892 23.7219 31.1089 23.796 30.9279 23.796C30.7484 23.796 30.5688 23.7234 30.4307 23.5782C30.1554 23.2864 30.1539 22.8136 30.4286 22.5203C31.3568 21.5289 31.3597 19.8603 30.4342 18.8772C30.1589 18.5846 30.1589 18.1117 30.4342 17.8192C30.7096 17.5266 31.1547 17.5266 31.4301 17.8192C32.8943 19.3747 32.8929 22.0115 31.4273 23.5752Z' fill='#FF055F' />
                                                </svg>
                                            </div>
                                        </div>
                                        <a href={`/seriesonplay/${series.id}`} className='card__img'>
                                            <img src={series.poster_url} alt={series.title} />
                                        </a>
                                    </div>
                                ))
                            )
                        }

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Series
