// Classified page component.
import { useState, useEffect } from 'react'
import Nav from './Nav'
import Footer from './Footer'
import '../css/Classified.scss'
import axios from 'axios'
import { convertMicrosecondsToTimeString } from "../utils/utils";
import { useNavigate } from 'react-router-dom';

function Classified() {

    const navigate = useNavigate();

    const [mostPlayedVideo, setMostPlayedVideo] = useState(null);
    const [allArticlesTagArray, setAllArticlesTagArray] = useState([])
    const [updatedArticles, setUpdatedArticles] = useState([])

    const [allFilterTags, setAllFilterTags] = useState([])
    const [selectedTagIDs, setSelectedTagIDs] = useState(new Set());

    const [sortByTitle, setSortByTitle] = useState("");
    const [sortByPublishTime, setSortByPublishTime] = useState("desc");
    const [filterByToday, setFilterByToday] = useState(false);
    const [filterByCurrentMonth, setFilterByCurrentMonth] = useState(false);

    useEffect(() => {
        if (sortByTitle === "asc") {
            setUpdatedArticles(prev => [...prev].sort((a, b) => a.title_first_char.localeCompare(b.title_first_char)));
        } else {
            setUpdatedArticles(prev => [...prev].sort((a, b) => b.title_first_char.localeCompare(a.title_first_char)));
        }
    }, [sortByTitle]);

    useEffect(() => {
        if (sortByPublishTime === "desc") {
            setUpdatedArticles(prev => [...prev].sort((a, b) => new Date(b.publish_datetime) - new Date(a.publish_datetime)));
        } else {
            setUpdatedArticles(prev => [...prev].sort((a, b) => new Date(a.publish_datetime) - new Date(b.publish_datetime)));
        }
    }, [sortByPublishTime]);

    useEffect(() => {
        if (filterByToday) {
            setUpdatedArticles(prev => [...prev].filter(item => new Date(item.publish_datetime).toDateString() === new Date().toDateString()));
        } else {
            if (selectedTagIDs.size > 0) {
                let newUpdatedArticles = [];
                allArticlesTagArray.forEach(item => {
                    if (selectedTagIDs.has(item.tag_id)) {
                        newUpdatedArticles.push(...item.articles);
                    }
                });
                newUpdatedArticles = [...new Set(newUpdatedArticles)];
                setUpdatedArticles(newUpdatedArticles);
            }
        }
    }, [filterByToday, selectedTagIDs, allArticlesTagArray]);

    useEffect(() => {
        if (filterByCurrentMonth) {
            setUpdatedArticles(prev => [...prev].filter(item => new Date(item.publish_datetime).getMonth() === new Date().getMonth() && new Date(item.publish_datetime).getFullYear() === new Date().getFullYear()));
        } else {
            if (selectedTagIDs.size > 0) {
                let newUpdatedArticles = [];
                allArticlesTagArray.forEach(item => {
                    if (selectedTagIDs.has(item.tag_id)) {
                        newUpdatedArticles.push(...item.articles);
                    }
                });
                newUpdatedArticles = [...new Set(newUpdatedArticles)];
                setUpdatedArticles(newUpdatedArticles);
            }
        }
    }, [filterByCurrentMonth, selectedTagIDs, allArticlesTagArray]);

    useEffect(() => {
        document.title = '分类 - 7G';
        document.querySelector('#root').className = 'classified';

        const fetchFilterTags = async () => {
            const response = await axios.get('/wp-json/hello-world/v1/get-tags-list');
            setAllFilterTags(response.data);
        }
        fetchFilterTags();

        const fetchArticlesTagArrayByTagIdArray = async () => {
            const response = await axios.get('/wp-json/hello-world/v1/get-articles-tag-array-by-tag-id-array');
            setAllArticlesTagArray(response.data);
            setSelectedTagIDs(new Set(["201"]));
        }
        fetchArticlesTagArrayByTagIdArray();

        const fetchMostPlayedVideo = async () => {
            const response = await axios.get('/wp-json/hello-world/v1/get-most-played-video');
            setMostPlayedVideo(response.data);
        }
        fetchMostPlayedVideo();

    }, [])

    const handleTagChange = (id) => {
        const newSelectedTagIDs = new Set(selectedTagIDs);
        if (newSelectedTagIDs.has(id)) {
            newSelectedTagIDs.delete(id);
        } else {
            newSelectedTagIDs.add(id);
        }
        setSelectedTagIDs(newSelectedTagIDs);
    };

    useEffect(() => {

        if (selectedTagIDs.size > 0) {
            let newUpdatedArticles = [];
            allArticlesTagArray.forEach(item => {
                if (selectedTagIDs.has(item.tag_id)) {
                    newUpdatedArticles.push(...item.articles);
                }
            });
            newUpdatedArticles = [...new Set(newUpdatedArticles)];
            setUpdatedArticles(newUpdatedArticles);
        } else {
            setUpdatedArticles([]);
        }

    }, [selectedTagIDs, allArticlesTagArray]);

    // ------------ pagination start ------------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    const totalPages = Math.ceil(updatedArticles.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = updatedArticles.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    }

    // always show the first and the last page. Between the first and the 5 pages, and the 5 pages and the last page, if there are pages, emit them and show three dots; if not, show nothing.
    const getPaginationNumbers = () => {
        if (totalPages === 0) {
            return [];
        }
        const pageNumbers = [];

        // Always add first page
        pageNumbers.push(1);

        // Calculate middle pages
        let middleStart, middleEnd;
        if (currentPage <= 3) {
            middleStart = 2;
            middleEnd = Math.min(4, totalPages - 1);
        } else if (currentPage >= totalPages - 2) {
            middleStart = Math.max(totalPages - 3, 2);
            middleEnd = totalPages - 1;
        } else {
            middleStart = currentPage - 1;
            middleEnd = currentPage + 1;
        }

        // Add dots and middle pages
        if (middleStart > 2) {
            pageNumbers.push('...');
        }
        for (let i = middleStart; i <= middleEnd; i++) {
            pageNumbers.push(i);
        }
        if (middleEnd < totalPages - 1) {
            pageNumbers.push('...');
        }

        // Always add last page
        pageNumbers.push(totalPages);

        return pageNumbers;
    }
    // ------------ pagination end ------------

    return (
        <>
            <header className="header">
                <div className="container">
                    <Nav />
                </div>
            </header>

            <main className="content">
                <div className="container">

                    <aside className="sidebar">
                        <div className="sidebar_upper">
                            <h3 className="title">播放最多</h3>
                            {
                                mostPlayedVideo && mostPlayedVideo[0] && (
                                    <div className="card_body">
                                        <a className="card_body--link" href={`/seriesonplay/${mostPlayedVideo[0].associated_series_id}?video=${mostPlayedVideo[0].id}`}>
                                            <img src={mostPlayedVideo[0].poster_url} alt={mostPlayedVideo[0].title} />
                                        </a>
                                        <div className="card_body--content">
                                            <div className="card_body--upper">
                                                <a href={`/seriesonplay/${mostPlayedVideo[0].associated_series_id}?video=${mostPlayedVideo[0].id}`}>
                                                    <h4>{mostPlayedVideo[0].video_name}</h4>
                                                </a>
                                                <p>{convertMicrosecondsToTimeString(mostPlayedVideo[0].video_length)}</p>
                                            </div>
                                            <div className="card_body--lower">
                                                <p>{mostPlayedVideo[0].title}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className="filter-container">
                            <h3 className="title">类别</h3>
                            <ul className="filter-list">
                                {allFilterTags.map((tag) => (
                                    <li key={tag.id} >
                                        <input
                                            type="checkbox"
                                            id={tag.id}
                                            onChange={() => handleTagChange(tag.id)}
                                            name={tag.name}
                                            checked={selectedTagIDs.has(tag.id)}
                                        />
                                        <label htmlFor={tag.id}> {tag.name}</label>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </aside>

                    <section className="movies">
                        <div className="movie-filter">
                            <h3 className="title">视频</h3>

                            <div className="movie_pagination">
                                <span className="pagination-info">( 25 个视频) 显示 {updatedArticles.length} 结果中的 {indexOfFirstItem + 1}–{indexOfLastItem}</span>
                                <div className="pagination-controls">
                                    <button
                                        className="pagination-btn previous"
                                        disabled={currentPage === 1}
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    > « 上一页</button>
                                    <ul className="pagination-pages">
                                        {getPaginationNumbers().map((page, index) => {
                                            if (page === '...') {
                                                return <li className="pagination-page-dots" key={index}>{page}</li>;
                                            } else {
                                                return <li className={`pagination-page ${page === currentPage ? 'current' : ''}`} key={index}>
                                                    <button onClick={() => handlePageChange(page)}>{page}</button>
                                                </li>;
                                            }
                                        })}
                                    </ul>
                                    <button
                                        className="pagination-btn next"
                                        disabled={currentPage === totalPages}
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >下一页 »</button>
                                </div>
                            </div>

                            <div className="filter-bar">
                                <div className="filter-dropdown">
                                    <span
                                        className={`dropdown-icon ${sortByTitle === "asc" ? "bold" : ""}`}
                                        onClick={() => setSortByTitle(sortByTitle === "asc" ? "desc" : "asc")}
                                    >
                                        名称
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16" fill="none">
                                            <path d="M0.659141 6.68177H9.88629C10.0648 6.68177 10.2193 6.61659 10.3497 6.48612C10.48 6.35569 10.5455 6.20124 10.5455 6.02273C10.5455 5.84422 10.4801 5.68984 10.3497 5.55923L5.73612 0.945643C5.60577 0.815359 5.45135 0.75 5.27273 0.75C5.09411 0.75 4.9397 0.815359 4.80923 0.945643L0.195643 5.55923C0.0651783 5.6897 0 5.84422 0 6.02273C0 6.20121 0.0651783 6.35569 0.195643 6.48612C0.326252 6.61659 0.480667 6.68177 0.659141 6.68177Z" fill="#3E4954" />
                                            <path d="M9.88629 9.31836H0.659141C0.480523 9.31836 0.326108 9.38357 0.195643 9.51389C0.0651783 9.64436 0 9.79877 0 9.97728C0 10.1558 0.0651783 10.3103 0.195643 10.4407L4.80923 15.0542C4.93984 15.1847 5.09426 15.25 5.27273 15.25C5.45121 15.25 5.60577 15.1847 5.73612 15.0542L10.3497 10.4407C10.48 10.3103 10.5455 10.1558 10.5455 9.97725C10.5455 9.79877 10.4801 9.64436 10.3497 9.51386C10.2194 9.38343 10.0648 9.31836 9.88629 9.31836Z" fill="#D3D6E4" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="filter-options">
                                    <span
                                        className={`filter-tab ${filterByToday ? "bold" : ""}`}
                                        onClick={() => setFilterByToday(!filterByToday)}
                                    >今天</span>
                                    <span
                                        className={`filter-tab ${filterByCurrentMonth ? "bold" : ""}`}
                                        onClick={() => setFilterByCurrentMonth(!filterByCurrentMonth)}
                                    >本月</span>
                                </div>
                                {/* <div className="view-options">
                                    <span className="view-icon grid-view"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M3 5H21C21.2652 5 21.5196 4.89464 21.7071 4.7071C21.8946 4.51957 22 4.26521 22 4C22 3.73478 21.8946 3.48043 21.7071 3.29289C21.5196 3.10536 21.2652 3 21 3H3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4C2 4.26521 2.10536 4.51957 2.29289 4.7071C2.48043 4.89464 2.73478 5 3 5Z" fill="none" />
                                        <path d="M3 13.0003H21C21.2652 13.0003 21.5196 12.8949 21.7071 12.7074C21.8946 12.5198 22 12.2655 22 12.0002C22 11.735 21.8946 11.4807 21.7071 11.2931C21.5196 11.1056 21.2652 11.0002 21 11.0002H3C2.73478 11.0002 2.48043 11.1056 2.29289 11.2931C2.10536 11.4807 2 11.735 2 12.0002C2 12.2655 2.10536 12.5198 2.29289 12.7074C2.48043 12.8949 2.73478 13.0003 3 13.0003Z" fill="none" />
                                        <path d="M3 20.9998H21C21.2652 20.9998 21.5196 20.8944 21.7071 20.7069C21.8946 20.5193 22 20.265 22 19.9998C22 19.7345 21.8946 19.4802 21.7071 19.2926C21.5196 19.1051 21.2652 18.9998 21 18.9998H3C2.73478 18.9998 2.48043 19.1051 2.29289 19.2926C2.10536 19.4802 2 19.7345 2 19.9998C2 20.265 2.10536 20.5193 2.29289 20.7069C2.48043 20.8944 2.73478 20.9998 3 20.9998Z" fill="none" />
                                    </svg></span>
                                    <span className="view-icon list-view"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M3 11.0002H10C10.2652 11.0002 10.5196 10.8949 10.7071 10.7074C10.8946 10.5198 11 10.2655 11 10.0002V3.00024C11 2.73503 10.8946 2.48067 10.7071 2.29314C10.5196 2.1056 10.2652 2.00024 10 2.00024H3C2.73478 2.00024 2.48043 2.1056 2.29289 2.29314C2.10536 2.48067 2 2.73503 2 3.00024V10.0002C2 10.2655 2.10536 10.5198 2.29289 10.7074C2.48043 10.8949 2.73478 11.0002 3 11.0002ZM4 4.00024H9V9.00024H4V4.00024Z" fill="white" fillOpacity="0.2" />
                                        <path d="M14 11.0002H21C21.2652 11.0002 21.5196 10.8949 21.7071 10.7074C21.8946 10.5198 22 10.2655 22 10.0002V3.00024C22 2.73503 21.8946 2.48067 21.7071 2.29314C21.5196 2.1056 21.2652 2.00024 21 2.00024H14C13.7348 2.00024 13.4804 2.1056 13.2929 2.29314C13.1054 2.48067 13 2.73503 13 3.00024V10.0002C13 10.2655 13.1054 10.5198 13.2929 10.7074C13.4804 10.8949 13.7348 11.0002 14 11.0002ZM15 4.00024H20V9.00024H15V4.00024Z" fill="white" fillOpacity="0.2" />
                                        <path d="M3 21.9998H10C10.2652 21.9998 10.5196 21.8944 10.7071 21.7069C10.8946 21.5193 11 21.265 11 20.9998V13.9998C11 13.7345 10.8946 13.4802 10.7071 13.2926C10.5196 13.1051 10.2652 12.9998 10 12.9998H3C2.73478 12.9998 2.48043 13.1051 2.29289 13.2926C2.10536 13.4802 2 13.7345 2 13.9998V20.9998C2 21.265 2.10536 21.5193 2.29289 21.7069C2.48043 21.8944 2.73478 21.9998 3 21.9998ZM4 14.9998H9V19.9998H4V14.9998Z" fill="white" fillOpacity="0.2" />
                                        <path d="M14 21.9998H21C21.2652 21.9998 21.5196 21.8944 21.7071 21.7069C21.8946 21.5193 22 21.265 22 20.9998V13.9998C22 13.7345 21.8946 13.4802 21.7071 13.2926C21.5196 13.1051 21.2652 12.9998 21 12.9998H14C13.7348 12.9998 13.4804 13.1051 13.2929 13.2926C13.1054 13.4802 13 13.7345 13 13.9998V20.9998C13 21.265 13.1054 21.5193 13.2929 21.7069C13.4804 21.8944 13.7348 21.9998 14 21.9998ZM15 14.9998H20V19.9998H15V14.9998Z" fill="white" fillOpacity="0.2" />
                                    </svg></span>
                                </div> */}
                                <div className="sort-option">
                                    <span
                                        className={`sort-latest ${sortByPublishTime === "asc" ? "bold" : ""}`}
                                        onClick={() => setSortByPublishTime(sortByPublishTime === "asc" ? "desc" : "asc")}
                                    >最新
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M10.2934 16.2932L8.00041 18.5861V3.00024C8.00041 2.73503 7.89505 2.48067 7.70751 2.29314C7.51998 2.1056 7.26562 2.00024 7.00041 2.00024C6.73519 2.00024 6.48084 2.1056 6.2933 2.29314C6.10576 2.48067 6.00041 2.73503 6.00041 3.00024V18.5861L3.70741 16.2932C3.5188 16.1111 3.2662 16.0103 3.00401 16.0126C2.74181 16.0148 2.491 16.12 2.30559 16.3054C2.12018 16.4908 2.01501 16.7416 2.01273 17.0038C2.01045 17.266 2.11125 17.5186 2.29341 17.7072L6.29341 21.7072C6.48111 21.8944 6.73537 21.9995 7.00046 21.9995C7.26554 21.9995 7.51981 21.8944 7.70751 21.7072L11.7075 17.7072C11.8903 17.5188 11.9917 17.2659 11.9897 17.0034C11.9877 16.7408 11.8825 16.4895 11.6968 16.3039C11.5111 16.1182 11.2598 16.013 10.9973 16.011C10.7347 16.009 10.4819 16.1104 10.2934 16.2932Z" fill="white" fillOpacity="0.2" />
                                            <path d="M11 6.00048H21C21.2652 6.00048 21.5196 5.89513 21.7071 5.70759C21.8946 5.52006 22 5.2657 22 5.00049C22 4.73527 21.8946 4.48092 21.7071 4.29338C21.5196 4.10585 21.2652 4.00049 21 4.00049H11C10.7348 4.00049 10.4804 4.10585 10.2929 4.29338C10.1054 4.48092 10 4.73527 10 5.00049C10 5.2657 10.1054 5.52006 10.2929 5.70759C10.4804 5.89513 10.7348 6.00048 11 6.00048Z" fill="white" fillOpacity="0.2" />
                                            <path d="M21 8.00024H11C10.7348 8.00024 10.4804 8.1056 10.2929 8.29314C10.1054 8.48067 10 8.73503 10 9.00024C10 9.26546 10.1054 9.51981 10.2929 9.70735C10.4804 9.89488 10.7348 10.0002 11 10.0002H21C21.2652 10.0002 21.5196 9.89488 21.7071 9.70735C21.8946 9.51981 22 9.26546 22 9.00024C22 8.73503 21.8946 8.48067 21.7071 8.29314C21.5196 8.1056 21.2652 8.00024 21 8.00024Z" fill="white" fillOpacity="0.2" />
                                            <path d="M18 12H11C10.7348 12 10.4804 12.1054 10.2929 12.2929C10.1054 12.4804 10 12.7348 10 13C10 13.2652 10.1054 13.5196 10.2929 13.7071C10.4804 13.8947 10.7348 14 11 14H18C18.2652 14 18.5196 13.8947 18.7071 13.7071C18.8946 13.5196 19 13.2652 19 13C19 12.7348 18.8946 12.4804 18.7071 12.2929C18.5196 12.1054 18.2652 12 18 12Z" fill="white" fillOpacity="0.2" />
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="movies_list">
                            {
                                // if currentItems is empty, show a obivious message 
                                currentItems?.length === 0 ? (
                                    <div className="movie-card">
                                        <div className="movie-card--empty">
                                            <p>没有找到相关视频</p>
                                        </div>
                                    </div>
                                ) : (
                                    currentItems?.map((article, index) => (
                                        <div className="movie-card" key={index}>
                                            <div className="image-wrapper" onClick={() => { navigate(`/seriesonplay/${article.associated_series_id}?video=${article.id}`); }} >
                                                <img src={article.poster_url} alt={article.title} />
                                            </div>
                                            <div className="right">
                                                <div className="movie-info">
                                                    <a href={`/seriesonplay/${article.associated_series_id}?video=${article.id}`}>
                                                        <h3 className="movie-title">{article.title}</h3>
                                                    </a>
                                                    <p className="movie-details">
                                                        {convertMicrosecondsToTimeString(article.video_length)}
                                                    </p>
                                                    <p className="movie-description">
                                                        {/* if article.summary is longer than 50 characters, truncate it to 50 characters */}
                                                        {article.summary.length > 50 ? article.summary.substring(0, 50) + '...' : article.summary}
                                                    </p>
                                                </div>
                                                <div className="movie-actions">
                                                    {/* <div className="movie-rating">⭐⭐⭐⭐⭐</div> */}
                                                    <span className="like-button"><svg xmlns="http://www.w3.org/2000/svg" width="70" height="60" viewBox="0 0 70 60" fill="none">
                                                        <rect x="0.5" y="0.5" width="69" height="59" rx="19.5" stroke="#212121" />
                                                        <path d="M45.2796 20.2302C43.9339 18.7956 42.1379 18.0063 40.2236 18.0063C38.317 18.0063 36.5337 18.7911 35.2015 20.2166L34.6819 20.7742L34.1624 20.2175C32.826 18.7874 31.0384 18 29.1267 18C27.2167 18 25.4258 18.7865 24.0826 20.2175C21.3067 23.1908 21.305 28.0431 24.0826 31.0336L34.0847 41.7357C34.2494 41.9113 34.4657 42 34.6819 42C34.8982 42 35.1136 41.9113 35.2792 41.7357L45.283 31.0309C48.0547 28.0468 48.0538 23.2017 45.2796 20.2302ZM43.4862 29.1148C43.3206 29.2922 43.1043 29.3818 42.8872 29.3818C42.6718 29.3818 42.4564 29.294 42.2908 29.1184C41.9605 28.7654 41.9588 28.1934 42.2883 27.8386C43.4017 26.6393 43.4051 24.6209 42.295 23.4316C41.9647 23.0777 41.9647 22.5057 42.295 22.1518C42.6253 21.7979 43.1592 21.7979 43.4895 22.1518C45.2458 24.0335 45.2441 27.2231 43.4862 29.1148Z" fill="white" />
                                                    </svg></span>
                                                    <button className="play-button"
                                                        onClick={() => {
                                                            navigate(`/seriesonplay/${article.associated_series_id}?video=${article.id}`);
                                                        }}>立即播放</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )
                            }

                        </div>
                        <div className="movie_pagination">
                            <span className="pagination-info">显示 {updatedArticles.length} 结果中的 {indexOfFirstItem + 1}–{indexOfLastItem}</span>
                            <div className="pagination-controls">
                                <button className="pagination-btn previous" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>« 上一页</button>
                                <ul className="pagination-pages">
                                    {getPaginationNumbers().map((page, index) => {
                                        if (page === '...') {
                                            return <li className="pagination-page-dots" key={index}>{page}</li>;
                                        } else {
                                            return <li className={`pagination-page ${page === currentPage ? 'current' : ''}`} key={index}>
                                                <button onClick={() => handlePageChange(page)}>{page}</button>
                                            </li>;
                                        }
                                    })}
                                </ul>
                                <button className="pagination-btn next" disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>下一页 »</button>
                            </div>
                        </div>
                    </section>

                </div>
            </main>

            <Footer />
        </>
    );
}

export default Classified;