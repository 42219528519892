import { useState, useEffect, useRef } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import ImgSeriesOnplay1 from "../static/seriesonplay-image-1.png";
import ImgWechat from "../static/wechat.png";
import ImgSeries1 from "../static/series-related-1.png";
import ImgSeries2 from "../static/series-related-2.png";
import ImgSeries3 from "../static/series-related-3.png";
import Nav from './Nav';
import Footer from './Footer';
import "../css/SeriesOnplay.scss";
import axios from 'axios';
import { convertMicrosecondsToTimeString } from '../utils/utils';


function SeriesOnplay() {

    const navigate = useNavigate();

    const [currentVideo, setCurrentVideo] = useState({});
    const [series, setSeries] = useState([]);
    const [currentSeries, setCurrentSeries] = useState({});

    const [videoHeight, setVideoHeight] = useState(0);

    const { seriesId } = useParams();
    const [searchParams] = useSearchParams();
    const videoId = searchParams.get('video');
    const timestamp = searchParams.get('t');

    const videoRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [formattedTime, setFormattedTime] = useState("00:00/00:00");
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    useEffect(() => {
        document.title = "Series 播放 - 7G";
        document.querySelector('#root').className = 'seriesonplay'

        const fetchSeries = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/series_videos/${seriesId}`);
                setSeries(response.data);
                // 如果 videoId 存在，则设置 currentVideo 为 videoId 对应的视频 
                if (videoId) {
                    setCurrentVideo(response.data.find(video => video.id === videoId));
                } else {
                    setCurrentVideo(response.data[0]);
                }
            } catch (error) {
                console.log(error);
            }
        }

        const fetchCurrentSeries = async () => {
            try {
                const response = await axios.get(`/wp-json/hello-world/v1/series/${seriesId}`);
                setCurrentSeries(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchSeries();
        fetchCurrentSeries();

        if (timestamp) {
            videoRef.current.currentTime = timestamp;
        }

    }, [seriesId, videoId, timestamp]);

    useEffect(() => {
        if (videoRef.current) {
            // Calculate the height
            setVideoHeight(videoRef.current.offsetHeight);
          }
    }, [videoRef]);

    useEffect(() => {
        // Attempt to play the video when component mounts
        const playVideo = async () => {
          try {
            if (videoRef.current) {
              await videoRef.current.play();
            }
          } catch (error) {
            console.warn('Auto-play failed:', error);
            console.info('Video auto-play may be blocked by browser policy');
          }
        };
    
        playVideo();

        // when press spacebar, video playing pauses; press again, playing restart.
        const handleSpacebar = (event) => {
            if (event.code === 'Space') {
                event.preventDefault();
                handlePlayOrPause();
            }
        };
        document.addEventListener('keydown', handleSpacebar);

        // when press left/right arrow, it forward/backward 10 seconds.
        const handleArrowKey = (event) => {
            if (event.code === 'ArrowRight') {
                handleSeek(10);
            } else if (event.code === 'ArrowLeft') {
                handleSeek(-10);
            }
        };
        document.addEventListener('keydown', handleArrowKey);

        return () => {
            document.removeEventListener('keydown', handleSpacebar);
            document.removeEventListener('keydown', handleArrowKey);
        };
    }, []);

    const handleSeek = (seconds) => {
        videoRef.current.currentTime += seconds;
    };

    const handlePlayOrPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMuteIconShow = () => {
        if (videoRef.current.muted) {
            videoRef.current.muted = false;
            setIsMuted(false);
        } else {
            videoRef.current.muted = true;
            setIsMuted(true);
        }
    }

    const handleFullscreen = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.mozRequestFullScreen) { // Firefox
            videoRef.current.mozRequestFullScreen();
        } else if (videoRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) { // IE/Edge
            videoRef.current.msRequestFullscreen();
        }
    };

    const handleProgressChange = (event) => {
        const currentTime = (duration * event.target.value) / 100;
        videoRef.current.currentTime = currentTime;
        setProgress(event.target.value);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Math.floor(time % 60);
        return `${hours > 0 ? hours.toString().padStart(2, '0') : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleTimeUpdate = () => {
        setProgress((videoRef.current.currentTime / duration) * 100);
        setFormattedTime(`${formatTime(videoRef.current.currentTime)}/${formatTime(videoRef.current.duration)}`);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    return (
        <>
            <header className="header">
                <div className="container">
                    <Nav />
                </div>
            </header>

            <main className="series-onplay-container">
                <div className="container">
                    <div className="series-onplay">
                        {
                            currentVideo ? (
                                <video
                                    className="video"
                                    poster={currentVideo.poster_url || 'https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg'}
                                    ref={videoRef}
                                    src={currentVideo.download_link}
                                    onClick={handlePlayOrPause}
                                    onTimeUpdate={handleTimeUpdate}
                                    onLoadedMetadata={handleLoadedMetadata}
                                    autoPlay={true}
                                > </video>
                            ) : (<h3>视频加载中...</h3>)
                        }
                        <div className="controls">
                            <div className="controls_upside">
                                <input type="range" className="controls_upside--range" value={progress} min="0" max="100" onChange={handleProgressChange} />
                                <span className="controls_upside--time">{formattedTime}</span>
                            </div>
                            <div className="controls_downside">
                                <span className="controls_downside--left">
                                    {!isPlaying ?
                                        <span className="controls_downside--play" onClick={handlePlayOrPause}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                                <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                                <path d="M38.6015 25.5097L22.1505 14.3542C21.5467 13.946 20.7453 13.8845 20.0836 14.2017C19.4165 14.5165 19 15.1559 19 15.847V38.1506C19 38.849 19.4165 39.486 20.0836 39.8008C20.3656 39.9336 20.6741 40 20.9852 40C21.3912 40 21.8025 39.8795 22.1505 39.6409L38.6015 28.4953C39.1208 28.1387 39.424 27.5878 39.424 27.0025C39.4266 26.4073 39.1156 25.8589 38.6015 25.5097Z" fill="white" />
                                            </svg>
                                        </span> :
                                        <span className="controls_downside--play" onClick={handlePlayOrPause}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                                <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                                <rect x="18" y="14" width="6" height="26" fill="white" />
                                                <rect x="30" y="14" width="6" height="26" fill="white" />
                                            </svg>
                                        </span>
                                    }

                                    <span className="controls_downside--forward" onClick={() => handleSeek(-10)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M35.4265 18.5738C31.5028 14.6504 25.467 14.0572 20.8779 16.7981L20.957 16.3584C21.0064 16.0762 20.942 15.7859 20.7779 15.551C20.6139 15.3162 20.3634 15.1558 20.0814 15.1051C19.7995 15.0544 19.5089 15.1175 19.2732 15.2805C19.0376 15.4435 18.8761 15.6932 18.8242 15.9749L18.246 19.1887C18.2 19.445 18.2478 19.7093 18.3807 19.9332C18.5136 20.1572 18.7226 20.3258 18.9696 20.4082L21.7779 21.3445C21.9128 21.3895 22.0553 21.4075 22.1973 21.3974C22.3392 21.3874 22.4777 21.3495 22.605 21.2858C22.7323 21.2222 22.8458 21.1342 22.939 21.0267C23.0322 20.9192 23.1034 20.7945 23.1484 20.6595C23.1934 20.5245 23.2114 20.382 23.2013 20.2401C23.1913 20.0982 23.1534 19.9596 23.0898 19.8323C23.0261 19.7051 22.9381 19.5916 22.8306 19.4983C22.7232 19.4051 22.5984 19.3339 22.4634 19.2889L21.4925 18.9652C25.2853 16.3684 30.5355 16.7472 33.8945 20.1056C37.6956 23.9071 37.6956 30.0929 33.8945 33.8944C30.0922 37.6961 23.9075 37.6961 20.1052 33.8944C18.7854 32.57 17.8729 30.8951 17.4757 29.0681C17.0786 27.2411 17.2135 25.3385 17.8645 23.5858C17.9147 23.4524 17.9382 23.3103 17.9334 23.1679C17.9286 23.0254 17.8958 22.8852 17.8368 22.7554C17.7778 22.6257 17.6937 22.5088 17.5894 22.4116C17.4851 22.3144 17.3627 22.2387 17.2291 22.1889C17.0955 22.1391 16.9535 22.1161 16.811 22.1214C16.6685 22.1266 16.5285 22.1599 16.3989 22.2193C16.2693 22.2787 16.1527 22.3632 16.0559 22.4678C15.959 22.5724 15.8837 22.695 15.8343 22.8288C14.8845 25.3777 14.835 28.1749 15.6941 30.7559C16.5532 33.3368 18.2693 35.5463 20.5573 37.0175C22.8453 38.4886 25.5677 39.133 28.2724 38.8435C30.9772 38.5541 33.5016 37.3483 35.4265 35.4262C40.0724 30.7801 40.0724 23.22 35.4265 18.5738Z" fill="white" />
                                            <path d="M23.7499 32.4167C24.0372 32.4167 24.3128 32.3025 24.5159 32.0994C24.7191 31.8962 24.8332 31.6206 24.8332 31.3333V23.75C24.8332 23.5583 24.7823 23.37 24.6857 23.2044C24.5891 23.0388 24.4504 22.9018 24.2835 22.8073C24.1167 22.7129 23.9278 22.6644 23.7361 22.6668C23.5444 22.6693 23.3568 22.7225 23.1924 22.8211L20.4841 24.4461C20.2386 24.5944 20.062 24.834 19.9929 25.1123C19.9238 25.3906 19.9678 25.685 20.1154 25.9309C20.263 26.1768 20.5021 26.3541 20.7802 26.4241C21.0583 26.494 21.3528 26.4508 21.5991 26.3039L22.6666 25.6633V31.3333C22.6666 31.6206 22.7807 31.8962 22.9839 32.0994C23.1871 32.3025 23.4626 32.4167 23.7499 32.4167Z" fill="white" />
                                            <path d="M29.7076 32.4166C31.8336 32.4166 33.4993 30.2753 33.4993 27.5416C33.4993 24.8079 31.8337 22.6666 29.7076 22.6666C27.5816 22.6666 25.916 24.8079 25.916 27.5416C25.916 30.2754 27.5817 32.4166 29.7076 32.4166ZM29.7076 24.8333C30.4763 24.8333 31.3326 25.9454 31.3326 27.5416C31.3326 29.1379 30.4763 30.2499 29.7076 30.2499C28.939 30.2499 28.0826 29.1378 28.0826 27.5416C28.0826 25.9454 28.9392 24.8333 29.7076 24.8333Z" fill="white" />
                                        </svg>
                                    </span>
                                    <span className="controls_downside--backward" onClick={() => handleSeek(10)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M39.1785 23.4463C39.1258 23.2989 39.0443 23.1633 38.9388 23.0476C38.8333 22.9319 38.7058 22.8384 38.5638 22.7723C38.4219 22.7063 38.2682 22.6691 38.1117 22.663C37.9552 22.6569 37.7991 22.6819 37.6524 22.7366C37.5057 22.7913 37.3713 22.8746 37.257 22.9817C37.1428 23.0888 37.051 23.2175 36.9869 23.3604C36.9228 23.5033 36.8877 23.6575 36.8837 23.814C36.8797 23.9706 36.9069 24.1264 36.9636 24.2723C37.6739 26.1848 37.8211 28.2608 37.3878 30.2543C36.9545 32.2479 35.9588 34.0755 34.5188 35.5205C30.3702 39.6681 23.6209 39.6681 19.4735 35.5205C15.3261 31.3729 15.3261 24.6229 19.4735 20.475C23.131 16.8171 28.8461 16.3976 33.0006 19.2319L31.9456 19.5836C31.7983 19.6327 31.6622 19.7104 31.5449 19.8121C31.4276 19.9138 31.3316 20.0377 31.2622 20.1765C31.1928 20.3154 31.1514 20.4666 31.1404 20.6214C31.1294 20.7763 31.1491 20.9318 31.1982 21.0791C31.2473 21.2263 31.3249 21.3625 31.4266 21.4798C31.5284 21.597 31.6522 21.6931 31.7911 21.7625C31.9299 21.8319 32.0811 21.8733 32.236 21.8843C32.3908 21.8953 32.5463 21.8756 32.6936 21.8265L35.7576 20.8049C36.0271 20.715 36.2552 20.531 36.4002 20.2866C36.5452 20.0423 36.5973 19.7539 36.5471 19.4742L35.9164 15.9678C35.8597 15.6603 35.6835 15.3879 35.4264 15.21C35.1693 15.0321 34.8523 14.9633 34.5446 15.0187C34.2369 15.074 33.9637 15.2489 33.7847 15.5052C33.6056 15.7615 33.5354 16.0783 33.5893 16.3862L33.6743 16.8586C28.6799 13.8729 22.0864 14.5179 17.8018 18.8035C12.7327 23.8731 12.7327 32.1223 17.8018 37.192C19.9014 39.2913 22.6561 40.6088 25.6082 40.9257C28.5602 41.2426 31.5317 40.5397 34.029 38.9339C36.5263 37.3281 38.399 34.916 39.3357 32.0986C40.2725 29.2811 40.217 26.2279 39.1785 23.4463Z" fill="white" />
                                            <path d="M23.4505 33.9081C23.764 33.9081 24.0647 33.7836 24.2863 33.5619C24.508 33.3402 24.6325 33.0395 24.6325 32.726V24.4515C24.6325 24.2423 24.5769 24.0369 24.4716 23.8562C24.3662 23.6755 24.2148 23.526 24.0327 23.4229C23.8507 23.3199 23.6446 23.267 23.4354 23.2696C23.2263 23.2723 23.0216 23.3303 22.8422 23.438L19.8872 25.2111C19.6194 25.3729 19.4266 25.6343 19.3512 25.938C19.2758 26.2417 19.3239 26.5629 19.485 26.8312C19.646 27.0995 19.9068 27.293 20.2103 27.3693C20.5137 27.4456 20.8351 27.3984 21.1038 27.2382L22.2685 26.5392V32.726C22.2685 33.0395 22.3931 33.3402 22.6147 33.5619C22.8364 33.7836 23.137 33.9081 23.4505 33.9081Z" fill="white" />
                                            <path d="M29.9515 23.2695C27.6319 23.2695 25.8145 25.6059 25.8145 28.5888C25.8145 31.5717 27.6318 33.9081 29.9515 33.9081C32.2711 33.9081 34.0885 31.5717 34.0885 28.5888C34.0885 25.6059 32.2712 23.2695 29.9515 23.2695ZM29.9515 31.544C29.1129 31.544 28.1784 30.3305 28.1784 28.5888C28.1784 26.8471 29.1129 25.6336 29.9515 25.6336C30.79 25.6336 31.7245 26.847 31.7245 28.5888C31.7245 30.3305 30.7901 31.544 29.9515 31.544Z" fill="white" />
                                        </svg>
                                    </span>
                                </span>
                                <span className="controls_downside--right">
                                    {isMuted ?
                                        <span className="controls_downside--mute" onClick={handleMuteIconShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                                <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                                <path d="M34.4271 14.9327C34.4271 14.5799 34.2228 14.2457 33.9071 14.0972C33.5728 13.9301 33.2013 13.9858 32.9228 14.2086L24.6953 20.7632L34.4271 30.493V14.9327Z" fill="white" />
                                                <path d="M39.7284 38.4143L15.5847 14.2754C15.2225 13.9134 14.6338 13.9134 14.2716 14.2754C13.9095 14.6375 13.9095 15.2261 14.2716 15.5882L20.1144 21.4317H19.5702C18.9945 21.4317 18.4931 21.6916 18.1588 22.1001C17.8802 22.4158 17.713 22.8429 17.713 23.2885V30.7158C17.713 31.7371 18.5488 32.5727 19.5702 32.5727H23.879L32.9236 39.7958C33.0907 39.9257 33.295 40 33.4993 40C33.6293 40 33.7779 39.9629 33.9079 39.9072C34.2236 39.7586 34.4279 39.4244 34.4279 39.0716V35.7423L38.4135 39.7271C38.5955 39.909 38.8332 40 39.0709 40C39.3087 40 39.5464 39.909 39.7284 39.7289C40.0905 39.365 40.0905 38.7782 39.7284 38.4143Z" fill="white" />
                                            </svg>
                                        </span> :
                                        <span className="controls_downside--volume" onClick={handleMuteIconShow}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
                                                <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                                <path d="M24 16L16 22H10V32H16L24 38V16Z" fill="white" />
                                                <path d="M32.5 21.5C34.5 23.5 34.5 30.5 32.5 32.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                                <path d="M36.5 17.5C40.5 21.5 40.5 32.5 36.5 36.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </span>
                                    }
                                    <span className="controls_downside--fullscreen" onClick={handleFullscreen}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                            <circle opacity="0.2" cx="27" cy="27" r="27" fill="white" />
                                            <path d="M32.3867 15.1556L34.3978 17.3222L31.2376 20.5C30.663 21.0778 30.663 21.9444 31.2376 22.5222C31.8122 23.1 32.674 23.1 33.2486 22.5222L36.4088 19.3444L38.5635 21.3667C39.1381 21.9444 39.8564 21.5111 39.8564 20.9333V14.7222C39.8564 14.2889 39.5691 14 39.1381 14H32.9613C32.3867 14 31.9558 14.7222 32.3867 15.1556ZM15.1492 21.3667L17.1602 19.3444L20.3204 22.5222C20.895 23.1 21.7569 23.1 22.3315 22.5222C22.9061 21.9444 22.9061 21.0778 22.3315 20.5L19.3149 17.3222L21.326 15.1556C21.9006 14.7222 21.4696 14 20.895 14H14.7182C14.2873 14 14 14.2889 14 14.7222V20.9333C14 21.5111 14.7182 21.9444 15.1492 21.3667ZM21.326 38.7L19.1713 36.6778L22.3315 33.5C22.9061 32.9222 22.9061 32.0556 22.3315 31.4778C21.7569 30.9 20.895 30.9 20.3204 31.4778L17.1602 34.6556L15.0055 32.6333C14.7182 32.0556 14 32.4889 14 33.0667V39.2778C14 39.7111 14.2873 40 14.7182 40H20.895C21.4696 40 21.9006 39.2778 21.326 38.7ZM38.7072 32.4889L36.5525 34.5111L33.3923 31.3333C32.8177 30.7556 31.9558 30.7556 31.3812 31.3333C30.8066 31.9111 30.8066 32.7778 31.3812 33.3556L34.5414 36.5333L32.5304 38.7C32.0994 39.1333 32.3867 40 33.105 40H39.2818C39.7127 40 40 39.7111 40 39.2778V33.0667C39.8564 32.4889 39.1381 32.0556 38.7072 32.4889Z" fill="white" />
                                        </svg></span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar-above">
                        <div className="related-videos">
                            <div className="title">
                                {/* <h2>{currentSeries.name}</h2> */}
                                <h2>在同一系列中</h2>
                                <span>{series.length} 视频</span>
                            </div>

                            <ul className='series-items' 
                                style={ {
                                     height: `${videoHeight- 50}px`,
                                     minHeight: '40vh',
                                     maxHeight: '75vh'
                                } }
                            >
                                {
                                    series.map((item, index) => {
                                        return (
                                            // if (item.id === currentVideo.id) , add class 'current'
                                            <li className={item.id === currentVideo?.id ? 'series-item current' : 'series-item'} 
                                                key={item.id} 
                                                onClick={() => {
                                                    navigate(`/seriesonplay/${currentSeries.id}?video=${item.id}`);
                                                }}>
                                                <img 
                                                    src={item.poster_url || 'https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg'} 
                                                    alt={item.title} 
                                                    className="series-item--poster"
                                                />
                                                <span className='series-link'>
                                                    <h4>{item.title}</h4>
                                                    <div className="info">
                                                        {/* <span>{item.video_length}</span> */}
                                                        {/* <span>Published on</span>
                                                        <span>{item.update_datetime}</span> */}
                                                    </div>
                                                </span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </main>

            <section className="video-intro">
                <div className="container main-content">
                    <div className="intro">
                        <h1>{currentVideo?.title || ''}</h1>
                        <div className="video-info">
                            <span className="series-name">系列: {currentSeries?.name || ''}</span>
                            <span className="published-info">{convertMicrosecondsToTimeString(currentVideo?.video_length)}</span>
                            <span className="view-info">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 4C6.86912 4 2.40029 7.2226 0 12.0001C2.40029 16.7785 6.86912 20.0002 12 20.0002C17.1309 20.0002 21.5997 16.7785 24 12.0001C21.5997 7.2226 17.1309 4 12 4ZM12 17.0002C9.23818 17.0002 6.9999 14.7619 6.9999 12.0001C6.9999 9.23828 9.23818 7 12 7C14.7618 7 17.0001 9.23828 17.0001 12.0001C17.0001 14.7619 14.7618 17.0002 12 17.0002Z" fill="white" />
                                    <path d="M12 14.9999C13.6569 14.9999 15 13.6568 15 11.9999C15 10.3431 13.6569 8.99991 12 8.99991C10.3431 8.99991 9 10.3431 9 11.9999C9 13.6568 10.3431 14.9999 12 14.9999Z" fill="white" />
                                </svg>{currentVideo?.view_count || 0} viewers
                            </span>
                            {/* <span className="liked-info">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M18.404 8.11702H13.5585L14.6191 5.46602C14.7708 5.08833 14.8082 4.67443 14.7267 4.27567C14.6452 3.87691 14.4484 3.51086 14.1608 3.22294C13.999 3.06131 13.804 2.93684 13.5892 2.85816C13.3745 2.77949 13.1452 2.74849 12.9173 2.76733C12.6894 2.78617 12.4683 2.8544 12.2694 2.96726C12.0705 3.08013 11.8986 3.23494 11.7655 3.42094L8.93212 7.38552C8.50922 7.97613 8.03895 8.53132 7.52595 9.0456L6.61845 9.9531C6.57713 9.99437 6.54436 10.0434 6.52202 10.0973C6.49968 10.1513 6.48822 10.2091 6.48828 10.2675V17.0884C6.48821 17.2066 6.53502 17.32 6.61845 17.4038L7.63503 18.4249C7.89399 18.6852 8.202 18.8916 8.54123 19.0321C8.88046 19.1726 9.24419 19.2445 9.61136 19.2435H15.1352C15.5713 19.2432 15.998 19.1165 16.3637 18.8788C16.7294 18.6412 17.0184 18.3027 17.1959 17.9043L19.9055 11.8075C20.0789 11.4187 20.1679 10.9976 20.1668 10.5719V9.88069C20.1663 9.41324 19.9805 8.96507 19.65 8.63445C19.3196 8.30383 18.8715 8.11775 18.404 8.11702Z" fill="white" />
                                    <path d="M3.43442 9.35648C3.0097 9.35648 2.60238 9.5252 2.30205 9.82552C2.00173 10.1258 1.83301 10.5332 1.83301 10.9579V16.9795C1.83301 17.4042 2.00173 17.8115 2.30205 18.1118C2.60238 18.4122 3.0097 18.5809 3.43442 18.5809C3.85915 18.5809 4.26647 18.4122 4.5668 18.1118C4.86712 17.8115 5.03584 17.4042 5.03584 16.9795V10.9579C5.03584 10.5332 4.86712 10.1258 4.5668 9.82552C4.26647 9.5252 3.85915 9.35648 3.43442 9.35648Z" fill="white" />
                                </svg>{currentVideo?.like_count || 0} Liked
                            </span> */}
                            {/* <span className="share-icons">
                                <span className='share'>分享:</span>
                                <a href="#" className='wechat'>
                                    <img src={ImgWechat} alt="WeChat" />
                                </a>
                                <a href="#" className='facebook'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <rect x="0.447266" y="0.10553" width="30.5526" height="30.5526" rx="15.2763" fill="#2F4283" />
                                        <path d="M19.8476 10.5355H11.1762C10.7789 10.5355 10.4561 10.8492 10.4561 11.2344V19.5293C10.4561 19.9144 10.7789 20.2281 11.1762 20.2281H19.8476C20.2449 20.2281 20.57 19.9144 20.57 19.5293V11.2344C20.57 10.8492 20.2449 10.5355 19.8476 10.5355ZM13.5128 18.8434H12.0138V14.2178H13.5151V18.8434H13.5128ZM12.7633 13.5861C12.2824 13.5861 11.8941 13.2118 11.8941 12.7531C11.8941 12.2945 12.2824 11.9202 12.7633 11.9202C13.2419 11.9202 13.6325 12.2945 13.6325 12.7531C13.6325 13.214 13.2442 13.5861 12.7633 13.5861ZM19.1319 18.8434H17.6329V16.5934C17.6329 16.0568 17.6216 15.3667 16.854 15.3667C16.0729 15.3667 15.9533 15.9508 15.9533 16.5544V18.8434H14.4542V14.2178H15.8923V14.8496H15.9126C16.1136 14.4861 16.6034 14.1032 17.3326 14.1032C18.8497 14.1032 19.1319 15.0616 19.1319 16.3078V18.8434Z" fill="white" />
                                    </svg>
                                </a>
                                <a href="#" className='twitter'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                                        <rect x="0.552734" y="0.658264" width="27.4471" height="27.4471" rx="13.7236" fill="#0078BB" />
                                        <path d="M17.4902 12.5312C17.4955 12.6119 17.4955 12.6926 17.4955 12.7733C17.4955 15.2351 15.778 18.0715 12.6388 18.0715C11.6717 18.0715 10.7733 17.766 10.0176 17.2356C10.155 17.2529 10.2871 17.2587 10.4298 17.2587C11.2278 17.2587 11.9624 16.9646 12.549 16.4631C11.7985 16.4458 11.1697 15.9096 10.953 15.1717C11.0587 15.1889 11.1644 15.2005 11.2754 15.2005C11.4286 15.2005 11.5819 15.1774 11.7246 15.1371C10.9424 14.9641 10.3558 14.2146 10.3558 13.3095V13.2864C10.583 13.4248 10.8473 13.5113 11.1274 13.5228C10.6676 13.1884 10.3664 12.6177 10.3664 11.972C10.3664 11.6261 10.4509 11.309 10.5989 11.0322C11.4392 12.1622 12.7022 12.9002 14.1185 12.9809C14.0921 12.8425 14.0762 12.6984 14.0762 12.5543C14.0762 11.5281 14.8373 10.6921 15.7832 10.6921C16.2747 10.6921 16.7186 10.9169 17.0304 11.2802C17.4162 11.1994 17.7861 11.0438 18.1138 10.8305C17.987 11.2629 17.7174 11.6261 17.3634 11.8567C17.7069 11.8163 18.0398 11.7125 18.3463 11.5684C18.1138 11.9374 17.8232 12.266 17.4902 12.5312Z" fill="white" />
                                    </svg>
                                </a>
                            </span> */}
                        </div>
                        <div className="description">
                            {/* {currentVideo?.summary || ''} */}
                            {currentVideo?.content ? <div dangerouslySetInnerHTML={{ __html: currentVideo.content }} /> : ''}
                        </div>
                    </div>

                    <div className="sidebar-below">
                        <div className="related-videos">

                            <div className="title">
                                {/* <h2>{currentSeries?.name || ''}</h2> */}
                                <h2>在同一系列中</h2>
                                <span>{series.length} 视频</span>
                            </div>

                            <ul className='series-items'>
                                {
                                    series.map((item, index) => {
                                        return (
                                            <li className='series-item' 
                                                key={item.id} 
                                                onClick={() => {
                                                    navigate(`/seriesonplay/${currentSeries.id}?video=${item.id}`);
                                                }}>
                                                <img 
                                                    src={item.poster_url || 'https://websitevideos.oss-cn-beijing.aliyuncs.com/7g_poster_new.jpg'} 
                                                    alt={item.title} 
                                                    className="series-item--poster"
                                                />
                                                <span className='series-link'>
                                                    <h4>{item.title}</h4>
                                                    <div className="info">
                                                        {/* <span>{item.video_length}</span> */}
                                                        {/* <span>Published on</span>
                                                        <span>{item.update_datetime}</span> */}
                                                    </div>
                                                </span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>

                        </div>
                    </div>
                </div>
            </section>



            <Footer />
        </>
    );
}

export default SeriesOnplay;