import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import '../css/SearchResult.scss'
import Nav from './Nav'
import Footer from './Footer'
import axios from 'axios';

// arrow function
const SearchResult = () => {

    const [searchParams] = useSearchParams();
    const [searchResults, setSearchResults] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "搜索结果";
        document.querySelector('#root').className = 'search-result';
    },[])
    
    useEffect(() => {

        const keyword = searchParams.get('keyword');
        const cachedResults = sessionStorage.getItem(keyword);
        
        if (cachedResults) {
            setSearchResults(JSON.parse(cachedResults));
            setLoading(false);

        } else if (keyword) {
            axios.post('/wp-json/hello-world/v1/fuzzy-search-article-by-keyword', {
                keyword: keyword
            })
            .then(response => {
                setSearchResults(response.data);
                sessionStorage.setItem(keyword, JSON.stringify(response.data));
                setLoading(false);
            })
            .catch(error => {
                console.error('Search error:', error);
                setLoading(false);
            });
        }

    }, [searchParams]);

    if (loading) {
        return <div>加载中...</div>;
    }

    return (
        <>
            <header className='header'>
                <div className='container'>
                    <Nav />
                </div>
            </header>

            <section className='search-result__section'>
                <div className='container'>
                    <h1 className='search-result__title'>搜索结果</h1>

                    {searchResults.status == 'success' ? (
                        <div className='search-result__content'>
                            {searchResults?.data?.articles?.length > 0 ? (
                                <div className='search-result__group'>
                                    <h2 className='search-result__group-title'>相关视频</h2>
                                    <ul className='search-result__list'>
                                        {searchResults?.data?.articles?.map((video, index) => (
                                            <li key={index} className='search-result__item'>
                                                <a href={`/seriesonplay/${video.associated_series_id}?video=${video.id}`} className='search-result__link'>
                                                    <h2 className='search-result__item-title'>{index + 1}. {video.title}</h2>
                                                    <p className='search-result__item-desc'>{video.summary.slice(0, 20)}...</p>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null}
                            {searchResults?.data?.series?.length > 0 ? (
                                <div className='search-result__group'>
                                    <h2 className='search-result__group-title'>相关系列</h2>
                                    <ul className='search-result__list'>
                                        {searchResults?.data?.series?.map((series, index) => (
                                            <li key={index} className='search-result__item'>
                                                <a href={`/seriesonplay/${series.id}`} className='search-result__link'>
                                                    <h2 className='search-result__item-title'>{index + 1}. {series.name}</h2>
                                                    <p className='search-result__item-desc' dangerouslySetInnerHTML={{ __html: series.description.slice(0, 20) + '...' }} />
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null}
                            {searchResults?.data?.question_and_answer?.length > 0 ? (
                                <div className='search-result__group'>
                                    <h2 className='search-result__group-title'>相关问答</h2>
                                    <ul className='search-result__list'>
                                        {searchResults?.data?.question_and_answer?.map((question_and_answer, index) => (
                                            <li key={index} className='search-result__item'>
                                                <a href={`/question-and-answer?faqid=${question_and_answer.id}`} className='search-result__link'>
                                                    <h2 className='search-result__item-title'>{index + 1}. {question_and_answer.title}</h2> 
                                                    <p className='search-result__item-desc' dangerouslySetInnerHTML={{ __html: question_and_answer.content.slice(0, 20) + '...' }} />
                                                </a>
                                            </li>
                                        ))}
                                        </ul>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div className='search-result__empty'>没有找到相关内容</div>
                    )}
                </div>
            </section>

            <Footer />
        </>
    )
}

export default SearchResult;