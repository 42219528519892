
export const convertMicrosecondsToTimeString = (microseconds) => {
    const totalSeconds = Math.floor(microseconds / 1000000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const timeParts = [];
    if (hours) timeParts.push(`${hours} 小时`);
    if (minutes) timeParts.push(`${minutes} 分钟`);
    if (seconds) timeParts.push(`${seconds} 秒`);

    return timeParts.join(' ');
}