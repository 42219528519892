import React, { useState, useEffect } from "react";
import Nav from './Nav'
import Footer from './Footer'
import "../css/Application.scss";
import axios from 'axios';
import { Link } from 'react-router-dom';

function Application() {

    const [forUs, setForUs] = useState([]);
    const [forChurch, setForChurch] = useState([]);
    const [appPageQandA, setAppPageQandA] = useState([]);
    const [currentQandA, setCurrentQandA] = useState(100);

    useEffect(() => {
        document.title = '应用 - 7G'
        document.querySelector('#root').className = 'application'

        axios.get('/wp-json/hello-world/v1/for-us/')
            .then(response => {
                setForUs(response.data);
            })
            .catch(error => {
                console.error('Error fetching for-us data:', error);
            });

        axios.get('/wp-json/hello-world/v1/for-church/')
            .then(response => {
                setForChurch(response.data);
            })
            .catch(error => {
                console.error('Error fetching for-church data:', error);
            });

        axios.get('/wp-json/hello-world/v1/app-page-Q-and-A/')
            .then(response => {
                setAppPageQandA(response.data);
            })
            .catch(error => {
                console.error('Error fetching app-page-Q-and-A data:', error);
            });

    }, [])

    return (
        <>
            <header className='header'>
                <div className='container'>
                    <Nav />
                </div>
            </header>

            <section className='section__hero'>
                <div className='container'>

                    <div className="header__middle">
                        <h1 className="hero-title">探索信仰</h1>
                        <p className="hero-subtitle">For me: <br />开悟的自学模式</p>
                        <h2 className="section-title">信仰 问答</h2>
                        {/* <button className="cta-button">Call To Action</button> */}
                    </div>

                    <div className="header__bottom QA__invisible">
                        {
                            appPageQandA?.map((item, index) => (
                                <div    
                                    className={`card ${currentQandA === index ? 'card-selected' : ''}`}
                                    key={index}
                                    onClick={() => {
                                        if (currentQandA === index) {
                                            setCurrentQandA(100);
                                        } else {
                                            setCurrentQandA(index);
                                        }
                                    }}
                                >
                                    <h3>{item.title}</h3>
                                    <div className="card-image-container">
                                        <img
                                            src={`https://cdn-poster.oss-cn-shanghai.aliyuncs.com/image/large/image-QAndA-${index + 1}.png`}
                                            alt={item.title}
                                        />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className='section__QandA '>
                <div className="container">

                    {
                        appPageQandA?.map((item, index) => (
                            <div className={`card ${currentQandA === index ? 'card-selected' : ''}`} key={index}
                                onClick={() => {
                                    console.log(currentQandA, index);
                                    if (currentQandA === index) {
                                        setCurrentQandA(100);
                                    } else {
                                        setCurrentQandA(index);
                                    }
                                }}
                            >
                                <img
                                    src={`https://cdn-poster.oss-cn-shanghai.aliyuncs.com/image/large/image-QAndA-${index + 1}.png`}
                                    alt={item.title}
                                    className="card-image"
                                />
                                <h3>{item.title}</h3>
                            </div>
                        ))
                    }
                    
                </div>
            </section>

             {currentQandA !== 100 && (
                <section className='section__QandA-content'>
                    <div className="container">
                        <h4>{appPageQandA[currentQandA]?.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: appPageQandA[currentQandA]?.content }} />
                    </div>
                </section>
            )}

            <section className={`section__us ${currentQandA === 100 ? 'margin-top-10' : 'margin-top-3'}`} >
                <div className="container">
                    <h4 className="subtitle">For us：</h4>
                    <h2 className="title">使用视频帮助你带小组</h2>
                    <p className="description">看生动视频，论生活和信仰，为您和家人朋友提供贴近现实的分享素材、小组讨论材料，帮人们过有益身心灵的日常生活、更加认识神认识真理。</p>
                    <div className="card-group">
                        {
                            forUs?.map((item, index) => (
                                <div className="card card--video" key={index}>
                                    <Link to={`/seriesonplay/${item.associated_series_id}?video=${item.id}`}>
                                        <img
                                            src={item.poster_url}
                                            alt={item.title}
                                            className="card-image"
                                        />
                                    </Link>
                                    <Link to={`/seriesonplay/${item.associated_series_id}?video=${item.id}`}>
                                        <h3>{item.title}</h3>
                                    </Link>
                                    <p>{item.summary}</p>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </section>

            <section className="section__church">
                <div className="container">
                    <h4 className="subtitle">For church：</h4>
                    <h2 className="title">讲道分享的视频好工具</h2>
                    <p className="description">用视频丰富您的讲章让会众享受耳目一新的讲道</p>
                    <div className="tags">
                        <span className="tag">信心</span>
                        <span className="tag">救赎</span>
                        <span className="tag">悔改</span>
                        <span className="tag">圣灵</span>
                        <span className="tag">爱心</span>
                        <span className="tag">盼望</span>
                        <span className="tag">医治</span>
                    </div>
                    <div className="card-group">
                        {
                            forChurch?.map((item, index) => (
                                <div className="card card--sermon" key={index}>
                                    <Link to={`/seriesonplay/${item.associated_series_id}?video=${item.id}`}>
                                        <img
                                            src={item.poster_url}
                                            alt={item.title}
                                            className="card-image"
                                        />
                                    </Link>
                                    <Link to={`/seriesonplay/${item.associated_series_id}?video=${item.id}`}>
                                        <h3>{item.title}</h3>
                                    </Link>
                                    <p>{item.summary}</p>
                                </div>

                            ))
                        }

                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Application;
