import React, { useEffect } from 'react';
import Nav from './Nav';
import Footer from './Footer';
import '../css/privacy.scss';

function Privacy() {

    useEffect(() => {
        document.title = "7G.tv 隐私政策";
        document.querySelector("#root").className = "privacy";
    }, []);

    return (
        <>
            <header className="header">
                <div className="container">
                    <Nav />
                </div>
            </header>

            <div className="privacy-policy">
                <div className="privacy-policy__container">
                    <h1 className="privacy-policy__title" id="privacy-top">欢迎了解7G.tv隐私政策</h1>
                    <h2 className="privacy-policy__section-title" id="preface">前言</h2>
                    <p className="privacy-policy__text">7G.tv致力保持你对本网站的信任和信心，以下的隐私政策是来保障及保护你使用本网站时提供的个人资料</p>
                    <ul className="privacy-policy__list">
                        <li>7G.tv 收集哪些个人信息，如何收集、使用、储存和分享这些信息以及谁有权接触这些人个人信息。</li>
                        <li>什么是cookies，它们是怎么用的。</li>
                        <li>7G.tv 的用户在披露个人信息时有哪些选择。</li>
                        <li>7G.tv 的用户怎样接近、更新、控制、保护或删除他/她的信息。</li>
                        <li>如果7G.tv 的用户对7G.tv 的隐私政策有问题，他们应该和谁联系。</li>
                    </ul>
                    <p className="privacy-policy__text">当您使用7G.tv 服务，即表示您信赖我们对您的信息的处理方式。此隐私权政策旨在帮助您了解我们会收紧哪些数据、为什么收集这些数据以及会利用这些数据做些什么。了解这些内容至关重要，因此我们希望您能抽出时间仔细阅读此政策。请注意，您可以在我的帐户中找到一些控制项，通过这些控制项，您可以管理自己的信息并保护自己的隐私和安全在阅读完本政策之后，如您对本《隐私政策》或与本《隐私政策》相关的事宜有任何问题，请与report@7g.tv联系。</p>

                    <h2 className="privacy-policy__section-title" id="collect">我们收集的信息</h2>
                    <p className="privacy-policy__text">我们收集信息是为了向所有用户提供更好的服务，其中既包括一些推断出来的基本信息（例如：您使用的语言），也包括一些较为复杂的信息（例如：您觉得那些广告最有用、网上的哪些人对您最重要，或您可能喜欢哪些 7G.tv 视频和 7Gsong的音乐）。</p>
                    <p className="privacy-policy__text">我们收集信息的方式如下：</p>
                    <ul className="privacy-policy__list">
                        <li>
                            <h3 className="privacy-policy__subsection-title">您向我们提供的信息：</h3>
                            <p className="privacy-policy__text">我们的很多服务都要求您注册 7G.tv帐户，而当您注册7G.tv账户时，我们会要求您提供个人信息，例如：您的姓名、电子邮件地址、电话号码或信用卡号，这些信息会储存在您的帐户名下。如果您想充分利用我们提供的各种分享功能，可能还需要创建公开显示的7G.tv个人资料，而个人资料中可能包含您的姓名和照片。</p>
                        </li>
                        <li>
                            <h3 className="privacy-policy__subsection-title">我们在您使用服务的过程中获取的信息：</h3>
                            <p className="privacy-policy__text">当您访问我们的网站，我们会收集一些<strong>非识别的基本信息</strong>。这包括访问者总数，浏览过的网页，花费的时间在网站上，指网站和其他非识别的信息。我们收集关于哪些国家和域我们的访客来源，并利用这些分析来改进我们的网站，使您尽可能顺利地体验广泛的统计信息。</p>
                            <p className="privacy-policy__text">我们的网络服务器应用了“<strong>cookies</strong>”来追踪用户的活动我们的网站以及记录每个访问者的IP地址范围内。Cookies是存储在计算机上，并且可以随时取出一个小的数据文件。一个cookies包含一个独特的识别功能，允许当您返回到我们的网站我们的计算机能够识别您。你来自哪里，而不是任何其他个人信息个私人信息的访问，记录的IP地址告诉我们，除非你选择通过填写并提交我们网站上的表单与我们分享。</p>
                            <p className="privacy-policy__text">当您进行<strong>在线捐赠</strong>，我们需要您的姓名、地址、电话、电子邮件和信用卡等信息。我们使用这些信息来处理您的捐款，并确认信用卡数据。我们可能会为了解决捐赠处理问题或其他问题，如果有必要与您联系。我们使用您的邮件地址来发送你的免税收据，并随时向你通报一下我们在世界各地做的事情。</p>
                        </li>
                    </ul>

                    <h2 className="privacy-policy__section-title" id="use">我们如何使用收集到的信息</h2>
                    <p className="privacy-policy__text">7G.tv使用你的资料，作为我们了解你的需要，以提供更好的网上服务，我们或会使用你的资料以完成一个交通、发出奉献收条、或使网站更个人化，如果您愿意，我们也会是用你的资料，通知我们最新的事工项目和活动。信用卡号码仅用作奉献或其它付款之用，除此之外，我们不会保留有关资料。如果你要求，你可随时选择删除在我们邮件清单上的名字。请参考以下私隐政策的相关条款。</p>
                    <p className="privacy-policy__text">另外，如果你不同意，7G.tv不会向你发出电邮，倘若你同意接受电子邮件，我们会定期向你发放信息及活动资料，如果你选择向我们提供你的邮寄地址，我们或会同时邮寄有关资料给你。</p>

                    <h2 className="privacy-policy__section-title" id="infor">信息安全</h2>
                    <p className="privacy-policy__text">我们努力为7G.tv和我们的用户提供保护，以免我们保存的信息在未经授权的情况下被访问、纂改、披露或破坏。为此，我们特别采取了一下措施：</p>
                    <ul className="privacy-policy__list">
                        <li>我们使用 SSL 对许多服务进行加密。</li>
                        <li>我们会审查信息收集、存储和处理方面的做法（包括物理性安全措施），以避免各系统遭到未经授权的访问。</li>
                        <li>我们只允许那些为了帮我们处理个人信息而需要知晓这些信息的7G.tv员工、承包商和代理商访问个人信息，而且他们需要履行严格的合同保密义务，如果其未能履行这些义务，就可能会被追究法律责任或被终止其与7G.tv的关系。</li>
                    </ul>

                    <h2 className="privacy-policy__section-title" id="third-party">与第三方连结</h2>
                    <p className="privacy-policy__text">本网站有连结至其它机构或他人网站的网上文件，当你探访那些网站时，请注意，7G.tv将不负责该等网站的私隐保障或内容资料。</p>

                    <h2 className="privacy-policy__section-title" id="age">年龄限制</h2>
                    <p className="privacy-policy__text">我们鼓励父母或监护人知道未满十八岁的未成年人使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交个人信息之前寻求父母或监护人的同意和指导。</p>

                    <h2 className="privacy-policy__section-title" id="modify">隐私政策的修改</h2>
                    <p className="privacy-policy__text">我们可能随时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。如该等修改造成您在本《隐私政策》下权利的实质减少，您可以选择停止使用我们向您提供的服务；在该种情况下，若您仍然继续使用我们的服务的，即表示同意受经修订的本《隐私政策》的约束。</p>
                    <p className="privacy-policy__text">最后，您是唯一对您的帐号和密码信息有保密责任的人。任何情况下，请小心妥善保管。</p>
                    <p className="privacy-policy__text">有关本声明或7G.tv的隐私措施的问题请与7G.tv的网站协调人联系。地址是report@7g.tv</p>
                    <a href="#privacy-top" className="privacy-policy__top-link">回到顶部</a>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Privacy;